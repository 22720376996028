import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getNewsDetail } from '../../../../../utile/slice/boardSlice';
import PaginationBoard from '../../../../common/PaginationBoard';

function Board() {
  const navigate = useNavigate();
  const seqNum = useParams();

  const dispatch = useAppDispatch();

  const newsDetail = useAppSelector(state => state.board.newsDetail);
  
  useEffect(() => {
    dispatch(getNewsDetail(seqNum.bbsSeq));
  }, [seqNum]);

  const goDetail = (seq) => {
    navigate(`/customer/news/${seq}`);
  }

  const goList = () => {
    navigate('/customer/news', {state: newsDetail.currentPageNo});
  }
  
  return (
    <>
      <div className="board" key={newsDetail.bbsSeq}>
        <span className="date">{newsDetail.createDate}</span>
        <strong className="tlt">{newsDetail.bbsTitNm}</strong>
        <div className="cont">
          <p className="desc" dangerouslySetInnerHTML={{ __html: newsDetail.bbsCont }} />
        </div>
      </div>
      <PaginationBoard detailData={newsDetail} goDetail={goDetail} goList={goList} />
    </>
  );
}

export default Board;
