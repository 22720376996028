import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getNoticeList } from '../../../../utile/slice/boardSlice';
import { actIsLoading } from '../../../../utile/slice/loadingSlice';
import PaginationList from '../../../common/pagination/BoardPagination';

function NoticeList() {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const noticeList = useAppSelector(state => state.board.noticeList);
  const topFixedNoticeList = useAppSelector(state => state.board.listFixed);
  const Page = useAppSelector(state => state.board.paginationData);
  const [pageNo, setPageNo] = useState(state ? state : 1);

  useEffect(() => {
    dispatch(getNoticeList(pageNo));
  }, [pageNo]);

  const onChangePageNo = (pageNum) => {
    setPageNo(pageNum);
  }

  const onClickList = () => {
    dispatch(actIsLoading(true));
    setTimeout(() => {
      dispatch(actIsLoading(false));
    }, 2000);
  }

  return (
    <>
      <ul className="list_wrap1">
        {topFixedNoticeList.map((notice) => (
          <li key={notice.bbsSeq}>
            <Link 
            to={"/customer/notice/" + notice.bbsSeq}
            state={"y"}
            title="페이지 이동" className="list"
            onClick={onClickList}
            >
              <div className="tlt_wrap">
                <strong className="tlt">
                  {notice.bbsTitNm}
                  <i className="ico_24_fix"></i>
                </strong>
                <span className="date">{notice.createDate}</span>
              </div>
            </Link>
          </li>
        ))}
        {noticeList.map((notice) => (
          <li key={notice.bbsSeq}>
            <Link 
            to={"/customer/notice/" + notice.bbsSeq}
            state={"n"}
            title="페이지 이동" className="list"
            onClick={onClickList}
            >
              <div className="tlt_wrap">
                <strong className="tlt">
                  {notice.bbsTitNm}
                </strong>
                <span className="date">{notice.createDate}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <PaginationList paginationData={Page} currentNum={pageNo} setCurrentNum={onChangePageNo} />
    </>
  );
}

export default NoticeList;
