import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImgLogo from '../../../assets/images/main/logo_t4k.png';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getNewsList, getNoticeList } from '../../../utile/slice/boardSlice';
import { actShowDepth1LPopUp } from '../../../utile/slice/layerPopUpSlice';
import ButtonMain from '../../main/ButtonMain';
import NoticePopUp from './NoticePopUp';
import { overviewData } from './maindata';

import EventImg from '../../../assets/images/img/img_event_line.png';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import LazyLoad from 'react-lazyload';

gsap.registerPlugin(ScrollTrigger);

function MainPage() {
  useEffect(() => {
    const handleGsap = () => {
      const target = document.querySelectorAll('[data-trigger]');
      if (!target) return;

      ScrollTrigger.matchMedia({
        scroller: '[data-snap]',
        // desktop
        '(min-width: 769px)': function () {
          target.forEach((el) => {
            gsap
              .timeline({
                scrollTrigger: {
                  trigger: el,
                  start: 'bottom bottom',
                  end: 'center 55%',
                  // markers: true,
                },
              })
              .to(el, {
                duration: 1.4,
                opacity: 1,
                y: 0,
              });
          });
        },

        //   mobile
        '(max-width: 769px)': function () {
          target.forEach((el) => {
            gsap
              .timeline({
                scrollTrigger: {
                  trigger: el,
                  start: 'center bottom',
                  end: 'center 55%',
                  // scrub: 1,
                  // markers: true,
                },
              })
              .to(el, {
                duration: 0.8,
                opacity: 1,
                y: 0,
              });
          });
        },
      });
    };

    handleGsap();
  }, []);
  const dispatch = useAppDispatch();

  const [selectedOverview, setSelectedOverview] = useState('0');
  const [deviceType, setDeviceType] = useState('');
  const [isObservedBrand, setIsObservedBrand] = useState(false);
  // const [tocuhLoaction, setTouchlocation] = useState(0);

  // overview onclick event
  const handleClickOverview = (event) => setSelectedOverview(event.target.name);

  useEffect(() => {
    const { innerHeight } = window;
    document.documentElement.style.setProperty('--app-height', `${innerHeight}px`);

    const handleResize = () => {
      if (window.innerWidth < 1180 && window.innerWidth > 768) {
        setDeviceType('ta');
      } else if (window.innerWidth < 768) {
        setDeviceType('mo');
      } else {
      }

      setDeviceType('pc');
    };

    handleResize();
    window.addEventListener('resize', () => handleResize());
    return () => {
      window.removeEventListener('resize', () => handleResize());
    };
  }, []);

  const newsList = useAppSelector((state) => state.board.newsList);
  const noticeList = useAppSelector((state) => state.board.listFixed);

  const { depth1LPopUpName } = useAppSelector((state) => state.layerPopUp);

  const [appCookies, setAppCookies] = useCookies();

  useEffect(() => {
    if (!appCookies.expire1DayNew) {
      //초기렌더링
      setAppCookies('expire1DayNew', false);
      dispatch(actShowDepth1LPopUp('notice'));
    } else if (appCookies.expire1DayNew === 'false') {
      dispatch(actShowDepth1LPopUp('notice'));
    }
  }, []);

  let pageNo = 1;

  useEffect(() => {
    dispatch(getNewsList(pageNo));
    dispatch(getNoticeList(pageNo));
  }, [pageNo]);

  // 이벤트라인닫기
  const [isOpenPopup, setIsOpen] = useState(true);

  return (
    <div className="main">
      {depth1LPopUpName === 'notice' && <NoticePopUp />}

      {isOpenPopup && (
        <div className="event_line">
          <div className="item">
            <button
              type="button"
              title="팝업 닫기"
              className="ico_24_progress_close mo_view"
              onClick={() => setIsOpen(false)}
            ></button>

            <img src={EventImg} alt="이밴트안내이미지" className="img pc_view" />

            <div className="txt_wrap">
              <strong className="tlt">
                T4K 계약하고
                <br className="txt_br" /> 8가지 웰컴선물 받으세요!
              </strong>
              <span className="info">
                재고 소진시 자동 종료되며, 사은품교환 및 반품은 제한될 수 있습니다.
              </span>
            </div>

            <div className="btn_wrap pc_view">
              <Link to="/customer/event/105" className="btn_radius">
                선물 받으러 가기
              </Link>
              <button
                type="button"
                title="팝업 닫기"
                className="btn_radius"
                onClick={() => setIsOpen(false)}
              >
                <i className="ico_24_progress_close"></i>닫기
              </button>
            </div>

            <Link to="/customer/event/105" className="btn_move mo_view">
              선물 받으러 가기
            </Link>
          </div>
        </div>
      )}

      <section className="main-keyviusal">
        <div className="inner">
          <h2 className="keyvisual-stit" data-trigger>
            마침내,
            <br />
            <img src={ImgLogo} alt="T4K" className="main-logo" />를 만나다
          </h2>
          <nav className="btnArea" data-trigger>
            <ButtonMain linkUrl={'/model/introduce'}>T4K 더 알아보기</ButtonMain>
            <ButtonMain linkUrl={'/buy/estimate/select'}>견적내기</ButtonMain>
          </nav>
          {/* <span className="btn-scroll" data-target="main-quick">
              <i className="scroll-wheel" />
              스크롤
            </span> */}
        </div>
        <video
          className="react-player"
          src={`./media/main/${deviceType}/video_main_teaser.mp4`}
          autoPlay={true}
          playsInline={true}
          muted={true}
          loop={true}
        >
          <source src={`./media/main/${deviceType}/video_main_teaser.mp4`} type="video/mp4" />
        </video>
      </section>
      <section className="main-quick">
        <div className="main-quick-cont left" data-trigger>
          <LazyLoad>
            <strong>T4K 구매상담</strong>
            <span>
              경제적인 EV 트럭 <br />
              T4K를 지금 바로 만나보세요.
            </span>
            <ButtonMain linkUrl="/buy/reservation">구매상담 신청하기</ButtonMain>
          </LazyLoad>
        </div>
        <div className="main-quick-cont right" data-trigger>
          <LazyLoad>
            <strong>판매/서비스 네트워크</strong>
            <span>
              편리한 판매/서비스 네트워크를
              <br />
              경험하세요.
            </span>
            <ButtonMain linkUrl={'/service/network'}>네트워크 찾아보기</ButtonMain>
          </LazyLoad>
        </div>
      </section>

      <section className="main-overview">
        <div className="overview-inner">
          <nav className="overview-btn-group">
            <button
              className={selectedOverview === '0' ? 'btn-overview active' : 'btn-overview'}
              name="0"
              onClick={handleClickOverview}
            >
              안전한 배터리
            </button>
            <button
              className={selectedOverview === '1' ? 'btn-overview active' : 'btn-overview'}
              name="1"
              onClick={handleClickOverview}
            >
              실외/실내 V2L
            </button>
            <button
              className={selectedOverview === '2' ? 'btn-overview active' : 'btn-overview'}
              name="2"
              onClick={handleClickOverview}
            >
              프리미엄 편의기능
            </button>
          </nav>
          {overviewData.map((item, idx) => {
            return (
              <figure
                key={idx}
                className={
                  Number(selectedOverview) === idx ? 'overview-cont active' : 'overview-cont'
                }
              >
                <video
                  className="react-player"
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                  loop={true}
                  controls={false}
                  src={get(item, `videoUrl${deviceType}`)}
                >
                  <source src={get(item, `videoUrl${deviceType}`)} type="video/mp4" />
                </video>
                <div className="inner">
                  <h2 className="overview-stit">{item.title}</h2>
                  <figcaption className={`fig-overview0${idx}`}>
                    <dl>
                      <dt>{item.message}</dt>
                      {item.keywords.map((item2, idx2) => {
                        return <dd key={idx2}>{item2}</dd>;
                      })}
                    </dl>
                  </figcaption>
                </div>
              </figure>
            );
          })}
        </div>
      </section>

      <section className="main-quick-menu">
        <div className="inner">
          <Link to={'/model/introduce'} className="btn-quick-menu">
            T4K 소개
          </Link>
          <Link to={'/buy/estimate/select'} className="btn-quick-menu">
            견적내기
          </Link>
          <Link to={'/buy/reservation'} className="btn-quick-menu">
            구매상담 신청
          </Link>
          <Link to={'/service/network'} className="btn-quick-menu">
            판매/서비스 네트워크
          </Link>
        </div>
      </section>

      {!isEmpty(newsList) && (
        <section className={!isEmpty(newsList) ? 'main-news' : 'main-news emptyNotice'}>
          <div className="inner">
            <h2 className="news-stit">
              <em>SNS</em>
              새로운 소식을 알려드려요.
            </h2>
            <Swiper slidesPerView="auto" navigation={true} className="news-slider">
              {newsList.map((item, idx) => {
                return (
                  <SwiperSlide className="news-slide" key={idx}>
                    <Link to={'/customer/news/' + item.bbsSeq} className="news-item">
                      <img src={item?.fileFullPath} className="news-image" />
                      <div className="news-texts">
                        <strong className="news-title">{item?.bbsTitNm}</strong>
                        <span className="news-datetime">{item?.createDate}</span>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>
      )}

      {!isEmpty(noticeList) && (
        <section className="main-announce">
          <div className="inner">
            <div className="inner-announce">
              <Swiper
                className="announce-slider"
                autoplay={noticeList.length > 1}
                loop={noticeList.length > 1 ? true : false}
                duration={5000}
                breakpoints={{
                  768: {
                    direction: 'vertical',
                  },
                  320: {
                    direction: 'horizontal',
                  },
                }}
              >
                {noticeList.map((item, idx) => {
                  return (
                    <SwiperSlide className="announce-slide" key={`announce-${idx}`}>
                      <h2 className="announce-stit">NOTICE</h2>
                      <Link to={'/customer/notice/' + item.bbsSeq} className="announce-url">
                        <strong>{item.bbsTitNm}</strong>
                        <span>{item.createDate}</span>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <i className="announce-divider" />
              <Link to={'/customer/notice'} className="announce-more">
                더보기
              </Link>
            </div>
          </div>
        </section>
      )}

      <section className={isObservedBrand ? 'main-brand doAnimate' : 'main-brand'}>
        <div className="inner">
          <h2 className="brand-stit" data-trigger>
            GS글로벌은 최적의 솔루션과<span className="desktop_br"></span> 최상의 가치를 제공하기
            위해 노력하고 있습니다
          </h2>
          <p className="brand-txt" data-trigger>
            GS글로벌이 선택한 경쟁력과 가치를 알아보세요.
          </p>
          <ButtonMain linkUrl={'/brand/gsg'}>GS글로벌 소개</ButtonMain>
          <div className="brand-link">
            <span className="brand-gsg mainBrand">GS글로벌</span>
            <i className="brand-divider mainBrand" />
            <span className="brand-byd mainBrand">BYD</span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default React.memo(MainPage);
