import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkName: '',
  checkNumber: '',
};

export const verifyMessageSlice = createSlice({
  name: 'verifyMessage',
  initialState,
  reducers: {
    actShowVerify: (state, { payload }) => {
      state.checkName = payload.reserNm;
      state.checkNumber = payload.reserContactNum;
    },
    actHideVerify: (state) => {
      state.checkName = '';
      state.checkNumber = '';
    },
  },
});

export const { actShowVerify, actHideVerify } = verifyMessageSlice.actions;

export default verifyMessageSlice;
