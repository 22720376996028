export const baseUrl = process.env.REACT_APP_API_ROOT;

const requests = {
  MAIN: '/api/mian',
  TEST: '',
  COMMON_CODE: '/api/commonCode',
  NEWS: '/api/news',
  NOTICE: '/api/notice',
  EVENT: '/api/alliance-event',

  // 사전예약
  CITY_LIST: '/api/getCityList',
  SEND_ADVRESER_FORM: '/api/reqAdvReserConsult',

  //구매예약
  SEND_PURCHASE_FORM: '/api/reqPurReservation',

  // 판매/서비스 네트워크
  SERVICE_NETWORK_LIST: '/api/getServiceNetworkList',
  SERVICE_NETWORK_DETAIL: '/api/getServiceNetworkDetail',
  SALES_MAN_LIST: '/api/getSalesManList',

  //서비스/부품정보
  SERVICE_PART: '/api/getPartsInfoList',

  // 견적
  subsidyPrice_LIST: '/api/getSubsidyPriceList',
  CONSIGNMENT_CHARGE_LIST: '/api/getConsignmentChargeList',
  PURC_RATE_LIST: '/api/getPurcRateList',
  //견적예약
  SEND_ESTIMATE_FORM: '/api/reqEstReservation',
};

export default requests;
