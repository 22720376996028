import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import RelationPage from '../../../common/RelationPage';

import imgSpaceConsoleMobile from '../../../../assets/images/introduce/mobile/img_console.jpg';
import imgSpaceDashboardMobile from '../../../../assets/images/introduce/mobile/img_dashboard.jpg';
import imgDesignFrontMobile from '../../../../assets/images/introduce/mobile/img_front.jpg';
import imgDesignRearMobile from '../../../../assets/images/introduce/mobile/img_rear.jpg';
import imgSpaceSeatMobile from '../../../../assets/images/introduce/mobile/img_seat.jpg';
import imgSpaceConsole from '../../../../assets/images/introduce/pc/img_console.jpg';
import imgSpaceDashboard from '../../../../assets/images/introduce/pc/img_dashboard.jpg';
import imgDesignFront from '../../../../assets/images/introduce/pc/img_front.jpg';
import imgDesignRear from '../../../../assets/images/introduce/pc/img_rear.jpg';
import imgSpaceSeat from '../../../../assets/images/introduce/pc/img_seat.jpg';
import imgSpaceConsoleTablet from '../../../../assets/images/introduce/tablet/img_console.jpg';
import imgSpaceDashboardTablet from '../../../../assets/images/introduce/tablet/img_dashboard.jpg';
import imgDesignFrontTablet from '../../../../assets/images/introduce/tablet/img_front.jpg';
import imgDesignRearTablet from '../../../../assets/images/introduce/tablet/img_rear.jpg';
import imgSpaceSeatTablet from '../../../../assets/images/introduce/tablet/img_seat.jpg';

import imgModelMobile1 from '../../../../assets/images/introduce/mobile/img_truck_1.jpg';
import imgModelMobile2 from '../../../../assets/images/introduce/mobile/img_truck_2.jpg';
import imgModel1 from '../../../../assets/images/introduce/pc/img_truck_1.jpg';
import imgModel2 from '../../../../assets/images/introduce/pc/img_truck_2.jpg';
import imgModelTablet1 from '../../../../assets/images/introduce/tablet/img_truck_1.jpg';
import imgModelTablet2 from '../../../../assets/images/introduce/tablet/img_truck_2.jpg';

import T4KCatalogPDF from '../../../../assets/pdfs/T4K_2023_catalog.pdf';

// import V2L_01_MO from '../../../../assets/images/introduce/mobile/V2L_01_MO.jpg';
// import V2L_02_MO from '../../../../assets/images/introduce/mobile/V2L_02_MO.jpg';

/* movie */
// import MovieItem1 from '../../../../assets/media/product_battery_movie.mp4';
import MovieDriving from '../../../../assets/media/model_driving.mp4';
import MovieTmap from '../../../../assets/media/tmap_text.mp4';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { desktopLnb, spaceDrivers } from './constants';

import LazyLoad from 'react-lazyload';

// import Section1 from './Section1';
// import Section4 from './Section4';
// import Section5 from './Section5';
// import Section6 from './Section6';
// import Section7 from './Section7';
// import Section11 from './Section11';
// import Section10 from './Section10';

const Section1 = React.lazy(() => import('./Section1'));
const Section4 = React.lazy(() => import('./Section4'));
const Section5 = React.lazy(() => import('./Section5'));
const Section6 = React.lazy(() => import('./Section6'));
const Section7 = React.lazy(() => import('./Section7'));
const Section10 = React.lazy(() => import('./Section10'));
const Section11 = React.lazy(() => import('./Section11'));
const SectionVisual = React.lazy(() => import('./SectionVisual'));

// section design
const designFrontList = [
  { id: 1, title: '아웃사이드 미러', desc: '전동 접이, 전동 조절, 열선, LED 방향지시등 등' },
  { id: 2, title: '헤드램프', desc: '클리어타입 헤드램프(4등식, 안개등, 주간주행등 포함)' },
  { id: 3, title: '엠블럼', desc: '알루미늄 소재 엠블럼' },
  { id: 4, title: '범퍼', desc: '' },
  // { id: 5, title: '휠커버', desc: '' },
  {
    id: 5,
    title: '배터리 프로텍터',
    desc: '좌/우/하부 배터리 프로텍터가 설치되어 배터리를 안전하게 보호',
  },
  { id: 6, title: '자외선 차단 유리(앞)', desc: '' },
  { id: 7, title: '안개등', desc: '' },
  {
    id: 8,
    title: '데칼',
    desc: 'T4K의 브랜드 심볼을 구성하는 기하학적 그래픽 요소를 시각적으로 확장한 디자인',
  },
  { id: 9, title: '에어로타입 와이퍼', desc: '' },
];
const designRearList = [
  { id: 1, title: '리어콤비램프', desc: '' },
  { id: 2, title: '리어 스텝퍼', desc: '원활한 화물 상하차를 위한 리어 스텝퍼 설치' },
  {
    id: 3,
    title: 'AC 예약 충전 ',
    desc: 'AC 예약 충전으로 원하는 시간대 충전 가능 충전요금 저렴한 심야시간대 선택 후 충전 진행',
  },
];
const spaceDashboardList = [
  {
    id: 1,
    title: '가죽 스티어링 휠',
    desc: '시동이 걸린 상태에서 스티어링 휠 히터 버튼을 누르면 스티어링 휠이 따뜻해집니다. 버튼을 다시 누르면 꺼지고 작동이 멈춥니다.',
  },
  { id: 2, title: '혼커버', desc: 'BYD 앰블럼이 들어간 혼커버' },
  { id: 3, title: '도어 맵포켓', desc: '넓은 공간감 제공하며, 물건 보관 활용도를 높임' },
  { id: 4, title: '카펫/카매트', desc: '카매트와 카펫이 편안한 바닥 재질을 제공합니다.' },
  { id: 5, title: '클러스터', desc: '주행정보를 확인 가능한 FULL LCD 클러스터' },
  { id: 6, title: '파워윈도우', desc: '' },
  // { id: 7, title: '선바이저 거울', desc: '' },
  { id: 8, title: '스마트패드', desc: '12.8인치 패드를 통해 차량 설정 확인 가능.' },
  { id: 9, title: '와이퍼 컨트롤 레버', desc: '' },
  {
    id: 10,
    title: '계기판 밝기 조절 및 주행거리 전환 버튼',
    desc: '계기판 밝기를 조절하고, 클러스터의 주행거리 표시 모드를 전환하여 보여줍니다.',
  },
];
const spaceConsoleList = [
  { id: 1, title: '크루즈 컨트롤', desc: '설정 주행속도로 일정하게 정속 주행' },
  {
    id: 2,
    title: '전동식 파킹 브레이크',
    desc: '버튼으로 파킹 브레이크 작동 또는 해제 가능, 정차 시 자동으로 제동상태 유지하는 오토홀드 기능 가능.',
  },
  { id: 3, title: '버튼시동 & 스마트키 적재함', desc: '버튼 타입 시동 시스템 적용' },
  { id: 4, title: '카드/티켓 홀더', desc: '' },
  { id: 5, title: '글러브 박스', desc: '조수석 앞쪽의 작은 물건 수납 공간 제공' },
  { id: 6, title: '실내V2L(220V 콘센트)', desc: '내부 220V 사용 가능(300W급)' },
  { id: 7, title: 'USB 충전단자', desc: '대시보드 중앙 아래쪽에 USB를 통한 충전이 가능합니다.' },
  {
    id: 8,
    title: '통풍/열선 컨트롤 버튼',
    desc: '리어글라스와 사이드미러 열선, 열선/통풍 시트 기본 장착, 열선과 통풍 스위치를 누르면 작동',
  },
];

gsap.registerPlugin(ScrollTrigger);

// gsap.registerPlugin(ScrollToPlugin);
const INITIAL_POPUP_STATES = {
  batteryMoviePopupShow: false,
  // v12Popup1Show: false,
  // v12Popup2Show: false,
};

const INITIAL_MOVIE_STATES = {
  MovieHero: true,

  movieState1: false,
  movieState2: false, // nugu
  visual01: false, // visual
  visual02: false,

  interior1: false,
  interior2: false,
  interior3: false,

  protect01: false,
  protect02: false,
};

const INITIAL_VIEW_INDEX = 0;

function Introduce() {
  const swiper = {};
  useEffect(() => {
    const handleGsap = () => {
      const target = document.querySelectorAll('[data-trigger]');
      if (!target) return;

      ScrollTrigger.matchMedia({
        scroller: '[data-snap]',
        // desktop
        '(min-width: 769px)': function () {
          target.forEach((el) => {
            gsap
              .timeline({
                scrollTrigger: {
                  trigger: el,
                  start: 'bottom bottom',
                  end: 'center 55%',
                  // markers: true,
                },
              })
              .to(el, {
                duration: 1.4,
                opacity: 1,
                y: 0,
              });
          });
        },

        //   mobile
        '(max-width: 769px)': function () {
          target.forEach((el) => {
            gsap
              .timeline({
                scrollTrigger: {
                  trigger: el,
                  start: 'center bottom',
                  end: 'center 55%',
                  // scrub: 1,
                  // markers: true,
                },
              })
              .to(el, {
                duration: 0.8,
                opacity: 1,
                y: 0,
              });
          });
        },
      });
    };

    handleGsap();
  }, []);

  const [popupState, setPopupState] = useState(INITIAL_POPUP_STATES);
  const [movieState, setMovieState] = useState(INITIAL_MOVIE_STATES);

  const viewportPc = useMediaQuery({ query: '(min-width: 1368px)' });
  const viewportMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const viewportTa = useMediaQuery({ query: '(max-width: 1367px)' });
  const viewportTaLand = useMediaQuery({ query: '(orientation: landscape)' });
  const viewportTaPort = useMediaQuery({ query: '(orientation: portrait)' });

  const [lnbNavActive, setLnbNavActive] = useState(false);

  const [priceDrivingCounts, setPriceDrivingCounts] = useState([0, 0, 0, 0, 0, 0]);

  // const [onScrolling, setOnScrolling] = useState(false)

  // const sectionLnbHandler = (target) => {
  //   const pageChildren = get(introduceRef, 'current.childNodes');

  //   pageChildren.forEach((item, idx) => {
  //     // const mobileIdx = (viewportMobile && idx > 6) ? (idx-1) : idx
  //     const mobileIdx = idx;
  //     console.log(item);

  //     if (target.id === mobileIdx) {
  //       item.classList.add('up', 'active');
  //     } else if (target.id > mobileIdx) {
  //       item.classList.add('up');
  //       item.classList.remove('active');
  //     } else {
  //       item.classList.remove('up', 'active');
  //     }
  //   });
  //   setScrollCount(target.id);

  //   gsap.to(window, {
  //     duration: 1.4,
  //     scrollTo: sectionRefs.current.sections[target.id],
  //     onStart: () => {
  //       window.onScrolling = true;
  //     },
  //     onComplete: () => {
  //       window.onScrolling = false;
  //     },
  //   });
  // };

  // const openBatteryMovie = (type) => {
  //   setPopupState({ batteryMoviePopupShow: type });
  //   setMovieState({ movieState1: type });
  // };

  const movieControl = (movieId, trigger) => {
    setMovieState((prevState) => {
      return {
        ...prevState,
        ...{
          ...{
            // MovieHero: false,
            movieState1: false,
            movieState2: false,
            visual01: false,
            visual02: false,
          },
          ...(movieId
            ? Array.isArray(movieId)
              ? movieId.reduce((a, v) => {
                  return { ...a, ...{ [v]: trigger } };
                }, {})
              : { [movieId]: trigger }
            : {}),
        },
      };
    });
  };

  //   if (sectionIndex !== sectionNumber && sectionNumber < sectionRefs.current.sections.length) {
  //     sectionIndex = sectionNumber;

  //     const selectSection =
  //       sectionIndex > 1
  //         ? sectionLnbs.find((item) => item.range.includes(sectionIndex))
  //         : { position: [0, 0] };
  //     // console.log(selectSection)

  //     if (sectionNumber === (!viewportMobile ? 16 : 15)) {
  //       setScrollEnd(true);
  //     }

  //     if (!selectSection) return false;

  //     // console.log(window.onScrolling)
  //     if (!window.onScrolling) {
  //       setSelectLnb([
  //         sectionIndex > 1 ? selectSection.position[0] : 0,
  //         sectionIndex ? selectSection.position[1] : 0,
  //       ]);

  //       setSectionLnbs((prevState) => {
  //         return prevState.map((lnb) => {
  //           return {
  //             ...lnb,
  //             ...{
  //               active: lnb.range.includes(sectionIndex),
  //             },
  //           };
  //         });
  //       });
  //     }

  //     movieControl();

  //     setLnbNavActive(false);

  //     // gnb.style.display = sectionIndex ? 'none' : 'block';

  //   }
  // };

  // popups
  const [designFrontState, setDesignFrontState] = useState(null);
  const [designRearState, setDesignRearState] = useState(null);
  const [spaceDashboardState, setSpaceDashboardState] = useState(0);
  const [spaceConsoleState, setSpaceConsoleState] = useState(0);
  const [spaceDriverState, setSpaceDriverState] = useState(0);

  const [designTabState, setDesignTabState] = useState(0);
  const [spaceTabState, setSpaceTabState] = useState(0);
  // const [featureTabState, setFeatureTabState] = useState(0);

  const inactivatePopups = () => {
    setPopupState(INITIAL_POPUP_STATES);

    setDesignFrontState(null);
    setDesignRearState(null);
    setSpaceDashboardState(null);
    setSpaceConsoleState(null);
    setSpaceDriverState(null);
  };

  const handleSpaceDriverClick = (item) => setSpaceDriverState(item);
  const designFrontHandler = (id) => setDesignFrontState(designFrontList[id]);
  const designRearHandler = (id) => setDesignRearState(designRearList[id]);
  const spaceDashboardHandler = (id) => setSpaceDashboardState(spaceDashboardList[id]);
  const spaceConsoleHandler = (id) => setSpaceConsoleState(spaceConsoleList[id]);

  // const spaceDriverHandler = (id) => {
  //   setSpaceDriverState(spaceDriverList[id]);
  // };
  // const initializePage = () => setLnbNavActive(false);

  // scroll renew work
  // const [scrollCount, setScrollCount] = useState(0);

  // const handleScrollFullPage = debounce((event) => {
  //   const isScrollDown = event.deltaY > 0;

  //   setScrollDirection(isScrollDown ? 'down' : 'up');
  //   setScrollCount(isScrollDown ? scrollCount + 1 : scrollCount - 1);

  //   inactivatePopups();
  // }, 100);

  // event handlers

  const activateLnb = () => setLnbNavActive((prevState) => !prevState);

  // const handleBatteryMovieClick = () => openBatteryMovie(true);

  const sectionRefs = useRef([]);
  const [viewIndex, setViewIndex] = useState(INITIAL_VIEW_INDEX);

  const options = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: [0.5],
  };

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      // const { pageYOffset } = window;
      const { dataset } = entry.target;

      // if (pageYOffset < targetOffsetTop)
      if (entry.intersectionRatio > 0.25) setViewIndex(dataset.index);
    });
  };

  console.log('viewIndex', viewIndex);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);

    // if (!sectionRefs.current || sectionRefs.current.length <= 0) return;

    sectionRefs.current.forEach((section) => observer.observe(section));
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleNavItemClick = (event, index) => {
    event.preventDefault();
    // const targetIndex = desktopLnb.find((item) => item.range.includes(Number(index))).range[0];

    sectionRefs?.current[index]?.scrollIntoView({
      // block: 'start',
      behavior: 'smooth',
      inline: 'center',
    });

    setLnbNavActive(false);
  };

  const [activeSectionName, setActiveSectionName] = useState('');

  useEffect(() => {
    if (viewIndex === '5') movieControl('visual01', true);
    // if (viewIndex === '7') movieControl(['interior2', 'interior3'], true);
    if (viewIndex === '7') movieControl('movieState2', true);
    if (viewIndex === '8') {
      // setPriceDrivingCounts([0, 0, 0, 0, 0, 0]);
      setTimeout(() => {
        setPriceDrivingCounts([190, 4200, 150, 330, 246, 53]);
      }, 100);
    }
    // if (viewIndex === '8') movieControl('movieState2', true);

    // if (viewIndex === 4) movieControl(['protect01', 'protect02'], false);
    // if (viewIndex === 5) movieControl('visual01', true);
    // if (viewIndex === 10) movieControl('movieState2', true);

    const temp = desktopLnb.find((item) => item.range.includes(Number(viewIndex)));
    if (temp) {
      setActiveSectionName(temp.name || '');
    }

    inactivatePopups();

    if (lnbNavActive) setLnbNavActive(false);
  }, [viewIndex]);

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, [1000]);
  }, []);

  // if (!isLoaded) return ;

  return (
    <div className="introduce">
      {/* <div className="after-loader" data-load={isLoaded}></div> */}
      {/* <Suspense> */}
      <nav className="section_lnb" style={{ top: viewIndex >= 2 ? '0px' : '-90px' }}>
        <div className="section_lnb_inner">
          <a href="/" className="lnb_logo_t4k">
            <span className="blind">T4K</span>
          </a>
          <button className="lnb_nav_mobile" onClick={activateLnb}>
            {activeSectionName}
            <i className="ico_drop_menu"></i>
          </button>
          {/* {(viewportPc) && ( */}
          {/* // todo... 동일한 테이터로 active와 link */}
          <div className={`catalog_list_box ${lnbNavActive ? 'active' : ''}`}>
            <ul className="section_lnb_list">
              {desktopLnb.map((item, i) => {
                const isActive = item.range.includes(Number(viewIndex));

                return (
                  <li key={i}>
                    <a
                      className={isActive ? 'is-active' : ''}
                      onClick={(event) => handleNavItemClick(event, item.range[0])}
                    >
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <ul className="catalog_list">
            <li>
              <a href={T4KCatalogPDF} target="_blank" rel="noreferrer">
                카탈로그
              </a>
            </li>
            {/* <li>
              <a href={T4KPricePDF} target="_blank">
                가격표<span> 다운로드</span>
              </a>
            </li> */}
          </ul>
        </div>
      </nav>

      <div
        className="section_wrap"
        data-index="0"
        ref={(element) => (sectionRefs.current[0] = element)}
      >
        <Section1 />

        {/* 2 */}
        <section
          className="section_summary"
          data-index="1"
          ref={(element) => (sectionRefs.current[1] = element)}
        >
          <div className="hero_bg"></div>
          <div className="inner_box">
            <h1 className="tit">
              <p className="object" data-trigger>
                화물차 운전자에게<span className="mobile_br"></span> 최적화된 기능만
                <span className="desktop_br"></span>
              </p>
              <p className="object" data-trigger>
                쏙쏙 뽑아 담은 전기트럭
              </p>
            </h1>
            <p className="desc object" data-trigger>
              안전하고 빠르게 충전하고 더 멀리 갈 수 있어요
            </p>
            <div className="summary_list">
              <ul>
                <li className="list_element_1 object" data-trigger>
                  <i className="ico_summary01"></i>
                  <strong>Blade-LFP</strong>
                  <em>
                    화재에 강한 <i className="desktop_br"></i>블레이드 LFP배터리
                  </em>
                </li>
                <li className="list_element_2 object" data-trigger>
                  <i className="ico_summary02"></i>
                  <strong>246Km</strong>
                  <em>
                    상온 주행시 최대 주행거리 <i className="desktop_br"></i>
                    (저온 주행시 최대 주행거리 209km)
                  </em>
                  <span>* 환경부 누리집 등재 기준</span>
                </li>
                <li className="list_element_3 object" data-trigger>
                  <i className="ico_summary03"></i>
                  <strong>V2L</strong>
                  <em>
                    V2L 커넥터를 활용하여<i className="desktop_br"></i>외부로 전력 공급 가능
                  </em>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* 3 */}
        <section
          className="section_battery"
          data-index="2"
          ref={(element) => (sectionRefs.current[2] = element)}
        >
          <div className="inner_box">
            <h1 className="tit object" data-trigger>
              Battery
            </h1>
            <h2 className="subhead object" data-trigger>
              배터리 폭발 불안해 하지 마세요
            </h2>
            <p className="desc object" data-trigger>
              낙하 관통 등 엄격한 배터리 안전 테스트에서도 <span className="mobile_br"></span>
              연기나 화재가 발생하지 않아 <span className="mobile_br"></span>
              <span className="desktop_br"></span>
              높은 안전성을 입증했습니다.
            </p>
            {/* {!popupState.batteryMoviePopupShow && (
                  <button href="#" className="btn_movie_controler" onClick={handleBatteryMovieClick}>
                    <span className="blind">재생</span>
                  </button>
                )} */}
            {/* 23.3.16 고객요청으로 주석처리 */}
          </div>
          <LazyLoad>
            <div className="img_battery object"></div>
          </LazyLoad>
        </section>

        {/* 4 */}
        <section data-index="3" ref={(element) => (sectionRefs.current[3] = element)}>
          <Section4 />
        </section>
        {/* 5 */}
        <section data-index="4" ref={(element) => (sectionRefs.current[4] = element)}>
          <Section5 viewIndex={viewIndex} />
        </section>
        {/* 6 */}
        <section
          className="section_visual"
          data-index="5"
          ref={(element) => (sectionRefs.current[5] = element)}
        >
          {/* <div id="asdasd">{'' + !!movieState.visual01}</div> */}
          <SectionVisual
            movieControl={movieControl}
            movieState={movieState}
            viewportPc={viewportPc}
            viewportTa={viewportTa}
          />
        </section>

        <section
          className="section_interior"
          data-index="6"
          ref={(element) => (sectionRefs.current[6] = element)}
        >
          <Section6 />
        </section>

        <section data-index="7" ref={(element) => (sectionRefs.current[7] = element)}>
          <Section7 />
        </section>

        <section
          className="section_navi"
          data-index="8"
          ref={(element) => (sectionRefs.current[8] = element)}
        >
          <LazyLoad height={606}>
            <div className="inner_box">
              <div className="logo_navi object">
                <span className="ico_t">
                  <span className="bind">T</span>
                </span>
                <span className="ico_nugu">
                  <span className="blind">NUGU</span>
                </span>
              </div>
              <div className="section_navi_movie">
                <ReactPlayer
                  url={MovieTmap}
                  playing={true}
                  loop={true}
                  muted={true}
                  playsinline={true}
                  width={'100%'}
                  height={'100%'}
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          </LazyLoad>
        </section>

        <section
          className="section_driving"
          data-index="9"
          ref={(element) => (sectionRefs.current[9] = element)}
        >
          <figure className="driving-movie">
            <LazyLoad height={657}>
              <ReactPlayer
                url={MovieDriving}
                playing={movieState.movieState2}
                loop={true}
                muted={true}
                playsinline={true}
                width={'100%'}
                height={'100%'}
                style={{ objectFit: 'cover' }}
              />
            </LazyLoad>
          </figure>
          <div className="driving_inner_box">
            <h1 className="tit object">Driving</h1>
            <ul className="spec_list">
              <li className="spec_1 object">
                <strong>최고출력</strong>
                {/* <em>190ps / 4200rpm</em> */}
                <span>
                  <CountUp end={priceDrivingCounts[0]} duration={1.5} />
                  <small>ps</small>
                  {' / '}
                  <CountUp end={priceDrivingCounts[1]} duration={1.5} />
                  <small>rpm</small>
                </span>
              </li>
              <li className="spec_2 object">
                <strong>모터 최대토크 (Nm)</strong>
                {/* <em>150 / 330</em> */}
                <span>
                  <CountUp end={priceDrivingCounts[2]} duration={1.5} /> /{' '}
                  <CountUp end={priceDrivingCounts[3]} duration={1.5} />
                </span>
              </li>
              <li className="spec_3 object">
                <strong>1회 충전 주행가능거리</strong>
                {/* <em>246km</em> */}
                <span>
                  <CountUp end={priceDrivingCounts[4]} duration={1.5} />
                  <small>km</small>
                  <p>
                    상온 주행시 가능거리 (저온 주행시 209km)
                    <br />
                    환경부 누리집 등재 기준
                  </p>
                </span>
              </li>
              <li className="spec_4 object">
                <strong>충전시간</strong>
                {/* <em>53분</em> */}
                <span>
                  <CountUp end={priceDrivingCounts[5]} duration={1.5} />
                  <small>분</small>
                  <p>
                    100kW 급속 충전기 기준으로 0%에서 80%까지 충전시 약 53분 소요
                    일반/초고압(초고속)충전기의 전압 모두 수용 가능<i className="desktop_br"></i>{' '}
                    충전속도 최대 92kW, 충전전압 범위 확장시켜주는 DC컨버터 장착
                  </p>
                </span>
              </li>
            </ul>
          </div>
          <div className="cautions">
            <p>
              ※ 본 차량의 제원은 차량의 활동 또는 성능개선, 관련 법규에 따라 변경될 수 있으며,
              상황에 따라 통지 없이 변경될 수 있습니다.
            </p>
            <p>
              ※ 배터리 충전 시간은 상온에서 측정하는 시험 결과 기준으로 배터리 온도, 충전기 출력,
              배터리 열화 정도, 외기 온도 등에 따라 실제와 차이가 있을 수 있습니다.
            </p>
            <p>
              ※ 겨울철 등 외기온도가 떨어지면 배터리 성능이 떨어져 실제 충전시간이 늘거나 주행거리가
              줄 수 있습니다.
            </p>
            <p>
              ※ 주행거리는 환경부 1충전 주행거리 인증 수치 기준에 따른 복합모드의 수치로 도로상황,
              운전방법, 차량적재·정비상태, 외부온도에 따라 실제와 차이가 있을 수 있습니다.
            </p>
          </div>
        </section>

        <section
          className="section_feature"
          data-index="10"
          ref={(element) => (sectionRefs.current[10] = element)}
        >
          <LazyLoad>
            <Section10 />
          </LazyLoad>
        </section>

        <section
          className="section_color"
          data-index="11"
          ref={(element) => (sectionRefs.current[11] = element)}
        >
          <Section11 />
        </section>

        <section
          className="section_design"
          data-index="12"
          ref={(element) => (sectionRefs.current[12] = element)}
        >
          <div className="detail_header">
            <div className="inner_box">
              <h1 className="tit">Design</h1>
              <div className="tab_buttons">
                <span className="mover" style={{ left: `${!designTabState ? 0 : 50}%` }}></span>
                {[{ name: 'Front' }, { name: 'Rear' }].map((e, i) => (
                  <button className="tab_btn" onClick={() => setDesignTabState(i)} key={i}>
                    {e.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <LazyLoad height="100vh" offset={2000}>
            {/* //tab button */}
            <div className="tab_content_box">
              {/* tab content 1 */}
              <div className={`tab_content tab_front ${!designTabState ? 'active' : ''}`}>
                <div className="img_wrap design-front">
                  {viewportPc && <img src={imgDesignFront} alt="1" />}
                  {viewportMobile && <img src={imgDesignFrontMobile} alt="2" />}
                  {viewportTa && viewportTaLand && <img src={imgDesignFront} alt="3" />}
                  {viewportMobile ||
                    (viewportTa && viewportTaPort && <img src={imgDesignFrontTablet} alt="4" />)}
                </div>
                <div className="btns_group">
                  {/* pos: [left, top] */}
                  {(viewportMobile
                    ? [
                        { name: '아웃사이드 미러', pos: [70, 39] },
                        { name: '헤드램프', pos: [56, 50] },
                        { name: '엠블럼', pos: [28, 50] },
                        { name: '범퍼', pos: [8, 56] },
                        { name: '배터리 프로텍터 / 배터리 지상고 높이', pos: [85, 56] },
                        { name: '자외선 차단 유리(앞)', pos: [43, 38] },
                        { name: '안개등', pos: [55, 56] },
                        { name: '데칼', pos: [66, 46] },
                        { name: '에어로타입 와이퍼', pos: [18, 42] },
                      ]
                    : viewportTa && viewportTaPort // tablet
                    ? [
                        { name: '아웃사이드 미러', pos: [70, 30] },
                        { name: '헤드램프', pos: [60, 43] },
                        { name: '엠블럼', pos: [32, 45] },
                        { name: '범퍼', pos: [15, 58] },
                        //{ name: '휠커버', pos: [57, 70] },
                        { name: '배터리 프로텍터 / 배터리 지상고 높이', pos: [82, 54] },
                        { name: '자외선 차단 유리(앞)', pos: [44, 30] },
                        { name: '안개등', pos: [56, 53] },
                        { name: '데칼', pos: [70, 39] },
                        { name: '에어로타입 와이퍼', pos: [22, 34] },
                      ]
                    : [
                        { name: '아웃사이드 미러', pos: [59, 40] },
                        { name: '헤드램프', pos: [52, 53] },
                        { name: '엠블럼', pos: [40, 57] },
                        { name: '범퍼', pos: [32, 72] },
                        // { name: '휠커버', pos: [57, 70] },
                        { name: '배터리 프로텍터 / 배터리 지상고 높이', pos: [65, 66] },
                        { name: '자외선 차단 유리(앞)', pos: [44, 38] },
                        { name: '안개등', pos: [52, 67] },
                        { name: '데칼', pos: [58, 49] },
                        { name: '에어로타입 와이퍼', pos: [35, 45] },
                      ]
                  ).map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      className={`btn_plus ${designFrontState?.id === index + 1 ? 'active' : ''}`}
                      style={{ left: `${item.pos[0]}%`, top: `${item.pos[1]}%` }}
                      onClick={() => designFrontHandler(index)}
                    >
                      <span className="blind">{item.name}</span>
                    </button>
                  ))}
                </div>
              </div>
              {/* //tab content 1 */}

              {/* tab content 2 */}
              <div className={`tab_content tab_rear ${designTabState ? 'active' : ''}`}>
                <div className="img_wrap design-rear">
                  {viewportPc && <img src={imgDesignRear} alt="" />}
                  {viewportMobile && <img src={imgDesignRearMobile} alt="" />}
                  {viewportTa && viewportTaLand && <img src={imgDesignRear} alt="" />}
                  {viewportMobile ||
                    (viewportTa && viewportTaPort && <img src={imgDesignRearTablet} alt="" />)}
                </div>
                <div className="btns_group">
                  {(viewportMobile
                    ? [
                        { name: '리어 콤비 램프', pos: [40, 40] },
                        { name: '리어 스텝퍼', pos: [20, 47] },
                        { name: 'AC 예약 충전', pos: [59, 44] },
                      ]
                    : viewportTa && viewportTaPort
                    ? [
                        { name: '리어 콤비 램프', pos: [40, 40] },
                        { name: '리어 스텝퍼', pos: [20, 47] },
                        { name: 'AC 예약 충전', pos: [59, 44] },
                      ]
                    : [
                        { name: '리어 콤비 램프', pos: [42, 59] },
                        { name: '리어 스텝퍼', pos: [26, 72] },
                        { name: 'AC 예약 충전', pos: [49, 67] },
                      ]
                  ).map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      // className="btn_plus"
                      className={`btn_plus ${designRearState?.id === index + 1 ? 'active' : ''}`}
                      style={{ left: `${item.pos[0]}%`, top: `${item.pos[1]}%` }}
                      onClick={() => designRearHandler(index)}
                    >
                      <span className="blind">{item.name}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className={`popup_detail ${designFrontState ? 'active' : ''}`}>
              {/* {designFrontList.map((item, i) => ( */}
              <div className="popup_dim" onClick={() => setDesignFrontState(null)} />
              <div className="popup_inner">
                <button
                  className="btn_pop_close"
                  onClick={() => setDesignFrontState(null)}
                ></button>
                {designFrontState && (
                  <div className="detail_layer_content front_content">
                    <div className="flex-left">
                      <div className={'img img' + designFrontState.id}></div>
                    </div>
                    <dl className="flex-right">
                      <dt>{designFrontState.title}</dt>
                      <dd>{designFrontState.desc}</dd>
                    </dl>
                  </div>
                )}
              </div>
            </div>
            <div className={`popup_detail ${designRearState ? 'active' : ''}`}>
              {/* {designFrontList.map((item, i) => ( */}
              <div className="popup_dim" onClick={() => setDesignRearState(null)} />
              <div className="popup_inner">
                <button className="btn_pop_close" onClick={() => setDesignRearState(null)}></button>
                {designRearState && (
                  <div className="detail_layer_content rear_content">
                    <div className="flex-left">
                      <div className={'img img' + designRearState.id}></div>
                    </div>
                    <dl className="flex-right">
                      <dt>{designRearState.title}</dt>
                      <dd>{designRearState.desc}</dd>
                    </dl>
                  </div>
                )}
              </div>
            </div>
          </LazyLoad>
        </section>

        <section
          id="section9"
          className="section_space"
          data-index="13"
          ref={(element) => (sectionRefs.current[13] = element)}
        >
          <div className="detail_header">
            <div className="inner_box">
              <h1 className="tit">Space</h1>
              <div className="tab_buttons">
                <span
                  className="mover"
                  style={{
                    left: `${[0, 33.3, 66.6][spaceTabState]}%`,
                    width: `${[33.3, 33.3, 33.3][spaceTabState]}%`,
                  }}
                ></span>
                {[{ name: 'Dashboard' }, { name: 'Console' }, { name: `Driver's seat` }].map(
                  (item, index) => (
                    <button
                      key={index}
                      className="tab_btn"
                      onClick={() => setSpaceTabState(index)}
                      style={{ width: '33.3%' }}
                    >
                      {item.name}
                    </button>
                  ),
                )}
              </div>
            </div>
          </div>
          <LazyLoad height="100vh" offset={2000}>
            <div className="tab_content_box">
              {/* tab content 1 */}
              <div className={`tab_content tab_space ${!spaceTabState ? 'active' : ''}`}>
                <div className="img_wrap left space-dashboard">
                  {viewportPc && <img src={imgSpaceDashboard} alt="" />}
                  {viewportMobile && <img src={imgSpaceDashboardMobile} alt="" />}
                  {viewportTa && viewportTaLand && <img src={imgSpaceDashboard} alt="" />}
                  {viewportMobile ||
                    (viewportTa && viewportTaPort && <img src={imgSpaceDashboardTablet} alt="" />)}
                </div>

                <div className="btns_group">
                  {(viewportMobile
                    ? [
                        { name: '가죽 스티어링 휠', pos: [22, 36] },
                        { name: '혼커버', pos: [40, 40] },
                        { name: '도어 맵포켓', pos: [16, 60] },
                        { name: '카펫/카매트', pos: [60, 70] },
                        { name: '클러스터', pos: [60, 38] },
                        { name: '파워윈도우', pos: [10, 51] },
                        // { name: '선바이저 거울', pos: [74, 16] },
                        { name: '스마트패드 / 인포테인먼트 / 스마트 음성인식', pos: [85, 40] },
                        { name: '와이퍼 컨트롤 레버', pos: [59, 45] },
                        { name: '계기판 밝기 조절 및 주행거리 전환 버튼', pos: [69, 47] },
                      ]
                    : viewportTa && viewportTaPort
                    ? [
                        { name: '가죽 스티어링 휠', pos: [26, 22] },
                        { name: '혼커버', pos: [38, 30] },
                        { name: '도어 맵포켓', pos: [22, 56] },
                        { name: '카펫/카매트', pos: [60, 60] },
                        { name: '클러스터', pos: [60, 25] },
                        { name: '파워윈도우', pos: [10, 47] },
                        // { name: '선바이저 거울', pos: [80, 12] },
                        { name: '스마트패드', pos: [85, 24] },
                        { name: '와이퍼 컨트롤 레버', pos: [60, 34] },
                        { name: '계기판 밝기 조절 및 주행거리 전환 버튼', pos: [70, 38] },
                      ]
                    : [
                        { name: '가죽 스티어링 휠', pos: [22, 38] },
                        { name: '혼커버', pos: [32, 40] },
                        { name: '도어 맵포켓', pos: [22, 78] },
                        { name: '카펫/카매트', pos: [44, 89] },
                        { name: '클러스터', pos: [46, 39] },
                        { name: '파워윈도우', pos: [15, 68] },
                        // { name: '선바이저 거울', pos: [70, 6] },
                        { name: '스마트패드', pos: [66, 46] },
                        { name: '와이퍼 컨트롤 레버', pos: [48, 50] },
                        { name: '계기판 밝기 조절 및 주행거리 전환 버튼', pos: [56, 54] },
                      ]
                  ).map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      // className="btn_plus"
                      name="spaceDashboard"
                      className={`btn_plus ${
                        spaceDashboardState?.id === index + 1 ? 'active' : ''
                      }`}
                      style={{ left: `${item.pos[0]}%`, top: `${item.pos[1]}%` }}
                      onClick={() => spaceDashboardHandler(index)}
                    >
                      <span className="blind">{item.name}</span>
                    </button>
                  ))}
                </div>
              </div>
              {/* //tab content 1 */}

              {/* tab content 2 */}
              <div className={`tab_content tab_console ${spaceTabState === 1 ? 'active' : ''}`}>
                <div className="img_wrap left space-console">
                  {viewportPc && <img src={imgSpaceConsole} alt="" />}
                  {viewportMobile && <img src={imgSpaceConsoleMobile} alt="" />}
                  {viewportTa && viewportTaLand && <img src={imgSpaceConsole} alt="" />}
                  {viewportMobile ||
                    (viewportTa && viewportTaPort && <img src={imgSpaceConsoleTablet} alt="" />)}
                </div>
                <div className="btns_group">
                  {(viewportMobile
                    ? [
                        { name: '크루즈 컨트롤', pos: [34, 49] },
                        { name: '전동식 파킹 브레이크', pos: [90, 66] },
                        { name: '버튼시동 & 스마트키 적재함', pos: [78, 62] },
                        { name: '카드/티켓 홀더', pos: [80, 48] },
                        { name: '글러브 박스', pos: [96, 48] },
                        { name: '실내V2L(220V 콘센트)', pos: [80, 52] },
                        { name: 'USB 충전단자', pos: [78, 56] },
                        { name: '통풍/열선 컨트롤 버튼', pos: [5, 55] }, //추가
                      ]
                    : viewportTa && viewportTaPort
                    ? [
                        { name: '크루즈 컨트롤', pos: [35, 38] },
                        { name: '전동식 파킹 브레이크', pos: [84, 60] },
                        { name: '버튼시동 & 스마트키 적재함', pos: [73, 55] },
                        { name: '카드/티켓 홀더', pos: [76, 38] },
                        { name: '글러브 박스', pos: [90, 38] },
                        { name: '실내V2L(220V 콘센트)', pos: [76, 43] },
                        { name: 'USB 충전단자', pos: [75, 46] },
                        { name: '통풍/열선 컨트롤 버튼', pos: [10, 45] }, //추가
                      ]
                    : [
                        { name: '크루즈 컨트롤', pos: [31, 55] },
                        { name: '전동식 파킹 브레이크', pos: [60, 91] },
                        { name: '버튼시동 & 스마트키 적재함', pos: [56, 82] },
                        { name: '카드/티켓 홀더', pos: [56, 56] },
                        { name: '글러브 박스', pos: [63, 55] },
                        { name: '실내V2L(220V 콘센트)', pos: [59, 62] },
                        { name: 'USB 충전단자', pos: [56, 65] },
                        { name: '통풍/열선 컨트롤 버튼', pos: [16, 70] }, //추가
                      ]
                  ).map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      // className="btn_plus"
                      className={`btn_plus ${spaceConsoleState?.id === index + 1 ? 'active' : ''}`}
                      style={{ left: `${item.pos[0]}%`, top: `${item.pos[1]}%` }}
                      onClick={() => spaceConsoleHandler(index)}
                    >
                      <span className="blind">{item.name}</span>
                    </button>
                  ))}
                </div>
              </div>
              <div className={`tab_content tab_seat ${spaceTabState === 2 ? 'active' : ''}`}>
                <div className="img_wrap right space-seat">
                  {viewportPc && <img src={imgSpaceSeat} alt="" />}
                  {viewportMobile && <img src={imgSpaceSeatMobile} alt="" />}
                  {viewportTa && viewportTaLand && <img src={imgSpaceSeat} alt="" />}
                  {viewportMobile ||
                    (viewportTa && viewportTaPort && <img src={imgSpaceSeatTablet} alt="" />)}
                </div>
                <div className="btns_group">
                  {/* // todo !viewportMobile !== desktop */}
                  {spaceDrivers.map((item, index) => {
                    const isActive = spaceDriverState?.id === index + 1;
                    // const leftPosition = `${viewportMobile ? item.mobilePos[0] : item.pos[0]}%`;
                    // const topPosition = `${viewportMobile ? item.mobilePos[1] : item.pos[1]}%`;
                    const leftPosition = `${
                      viewportMobile
                        ? item.mobilePos[0]
                        : viewportTa && viewportTaPort
                        ? item.tabletPos[0]
                        : item.pos[0]
                    }%`;
                    const topPosition = `${
                      viewportMobile
                        ? item.mobilePos[1]
                        : viewportTa && viewportTaPort
                        ? item.tabletPos[1]
                        : item.pos[1]
                    }%`;

                    return (
                      <button
                        key={index}
                        type="button"
                        className={`btn_plus ${isActive ? 'active' : ''}`}
                        style={{ left: leftPosition, top: topPosition }}
                        onClick={() => handleSpaceDriverClick(item)}
                      >
                        <span className="blind">{item.name}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={`popup_detail ${spaceDriverState ? 'active' : ''}`}>
              {/* {designFrontList.map((item, i) => ( */}
              <div className="popup_dim" onClick={() => setSpaceDriverState(null)} />
              <div className="popup_inner">
                <button
                  className="btn_pop_close"
                  onClick={() => setSpaceDriverState(null)}
                ></button>
                {spaceDriverState && (
                  <div className="detail_layer_content seat_content">
                    <div className="flex-left">
                      <div className={'img img' + spaceDriverState.id}></div>
                    </div>
                    <dl className="flex-right">
                      <dt>{spaceDriverState.name}</dt>
                      <dd>{spaceDriverState.desc}</dd>
                    </dl>
                  </div>
                )}
              </div>
            </div>
            <div className={`popup_detail ${spaceDashboardState ? 'active' : ''}`}>
              {/* {designFrontList.map((item, i) => ( */}
              <div className="popup_dim" onClick={() => setSpaceDashboardState(null)} />
              <div className="popup_inner">
                <button
                  className="btn_pop_close"
                  onClick={() => setSpaceDashboardState(null)}
                ></button>
                {spaceDashboardState && (
                  <div className="detail_layer_content dashboard_content">
                    <div className="flex-left">
                      <div className={'img img' + spaceDashboardState.id}></div>
                    </div>
                    <dl className="flex-right">
                      <dt>{spaceDashboardState.title}</dt>
                      <dd>{spaceDashboardState.desc}</dd>
                    </dl>
                  </div>
                )}
              </div>
            </div>
            <div className={`popup_detail ${spaceConsoleState ? 'active' : ''}`}>
              {/* {designFrontList.map((item, i) => ( */}
              <div className="popup_dim" onClick={() => setSpaceConsoleState(null)} />
              <div className="popup_inner">
                <button
                  className="btn_pop_close"
                  onClick={() => setSpaceConsoleState(null)}
                ></button>
                {spaceConsoleState && (
                  <div className="detail_layer_content console_content">
                    <div className="flex-left">
                      <div className={'img img' + spaceConsoleState.id}></div>
                    </div>
                    <dl className="flex-right">
                      <dt>{spaceConsoleState.title}</dt>
                      <dd>{spaceConsoleState.desc}</dd>
                    </dl>
                  </div>
                )}
              </div>
            </div>
          </LazyLoad>
        </section>

        <section
          id="section10"
          className="section_merit"
          data-index="14"
          ref={(element) => (sectionRefs.current[14] = element)}
        >
          <LazyLoad height="100vh" offset={2000}>
            {/* <div className="tab_buttons">
                  <span
                    className="mover"
                    style={{
                      width: `${[50, 50][featureTabState]}%`,
                      left: `${[0, 50][featureTabState]}%`,
                    }}
                  ></span>
                  {[{ name: 'T4K 내장탑차' }, { name: 'T4K 냉동탑차' }].map((e, i) => (
                    <button className="tab_btn" onClick={() => setFeatureTabState(i)} key={i}>
                      {e.name}
                    </button>
                  ))}
                </div> */}
            <Swiper
              slidesPerView={1}
              scrollbar={{ draggable: true }}
              pagination={{ clickable: true }}
              loop={true}
              navigation={true}
              data-index="99"
              ref={(element) => (sectionRefs.current[99] = element)}
            >
              <SwiperSlide>
                <div className="img_truck_1">
                  {viewportPc && <img src={imgModel1} alt="" />}
                  {viewportMobile && <img src={imgModelMobile1} alt="" />}
                  {viewportTa && viewportTaLand && <img src={imgModel1} alt="" />}
                  {viewportMobile ||
                    (viewportTa && viewportTaPort && <img src={imgModelTablet1} alt="" />)}
                </div>
                {/* // detail_header className 참고 */}
                <div className="model_header">
                  <div className="inner_box">
                    <h1 className="tit">Model</h1>
                    <p className="subhead">T4K특장 내장탑차</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_truck_2">
                  {viewportPc && <img src={imgModel2} alt="" />}
                  {viewportMobile && <img src={imgModelMobile2} alt="" />}
                  {viewportTa && viewportTaLand && <img src={imgModel2} alt="" />}
                  {viewportMobile ||
                    (viewportTa && viewportTaPort && <img src={imgModelTablet2} alt="" />)}
                </div>
                <div className="model_header">
                  <div className="inner_box">
                    <h1 className="tit">Model</h1>
                    <p className="subhead">T4K특장 냉동탑차</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </LazyLoad>
          {/* <div className="inner_box">
                <div className={`tab_content ${featureTabState === 1 ? 'active' : ''}`}></div>
                <div className={`tab_content ${featureTabState === 2 ? 'active' : ''}`}></div>
              </div> */}
        </section>

        <div className="relationPage">
          <RelationPage />
        </div>
      </div>

      {/* {!!popupState.batteryMoviePopupShow && (
        <div id="batteryMoviePopup">
          <ReactPlayer
            url={MovieItem1}
            playing={true}
            muted={true}
            loop={true}
            width={'100%'}
            height={'100vh'}
            style={{ objectFit: 'cover' }}
          />

          <video
            id="video"
            className="react-player"
            autoPlay={movieState.movieState1}
            playsInline={true}
            muted={true}
            loop={true}
            controls={false}
            style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            src={MovieItem1}
          >
            <source src={MovieItem1} type="video/mp4"></source>
          </video>

          <button onClick={() => openBatteryMovie(false)}>
            <span className="blind">close</span>
          </button>
        </div>
      )} */}
      {/* </Suspense> */}
    </div>
  );
}

export default Introduce;
