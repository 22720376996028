import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../api/ax';
import requests from '../../api/requests';

const initialState = {
    noticeList: [],
    newsList: [],
    eventList: [],
    listFixed: [],
    paginationData: {},
    noticeDetail: [],
    newsDetail: [],
    eventDetail: [],
};

// 공지사항
export const getNoticeList = createAsyncThunk('board/getNoticeList', async currentPageNo => {
    const res = await axios.get(requests.NOTICE + '?currentPageNo=' + currentPageNo);
    let noticeListData = res.data.data;
    return noticeListData;
});

export const getNoticeDetail = createAsyncThunk('board/getNoticeDetail', async seqNum => {
    const res = await axios.get(requests.NOTICE + '/' + seqNum);
    let noticeDetailData = res.data.data;
    return noticeDetailData;
});

export const getNoticeFixDetail = createAsyncThunk('board/getNoticeFixDetail', async seqNum => {
    const res = await axios.get(requests.NOTICE + '/' + seqNum + '?topFixYn=y');
    let noticeFixDetailData = res.data.data;
    return noticeFixDetailData;
});

// 뉴스
export const getNewsList = createAsyncThunk('board/getNewsList', async currentPageNo => {
    const res = await axios.get(requests.NEWS + '?currentPageNo=' + currentPageNo);
    let newsListData = res.data.data;
    return newsListData;
});

export const getNewsDetail = createAsyncThunk('board/getNewsDetail', async seqNum => {
    const res = await axios.get(requests.NEWS + '/' + seqNum);
    let newsDetailData = res.data.data;
    return newsDetailData;
});

// 이벤트
export const getEventList = createAsyncThunk('board/getEventList', async reqParams => {
    const res = await axios.get(requests.EVENT + '?currentPageNo=' + reqParams.currentPageNo + '&onGoingEventYn=' + reqParams.tab);
    let eventListData = res.data.data;
    return eventListData;
});

export const getEventDetail = createAsyncThunk('board/getEventDetail', async seqNum => {
    const res = await axios.get(requests.EVENT + '/' + seqNum);
    let eventDetailData = res.data.data;
    return eventDetailData;
});

const boardSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {
        
    },
    extraReducers: builder => {
        builder.addCase(getNoticeList.fulfilled, (state, { payload }) => {
            state.listFixed = payload.topFixedNoticeList;
            state.noticeList = payload.noticeList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getNoticeDetail.fulfilled, (state, { payload }) => {
            state.noticeDetail = payload.noticeApiDetailData;
        });
        builder.addCase(getNoticeFixDetail.fulfilled, (state, { payload }) => {
            state.noticeDetail = payload.noticeApiDetailData;
        });
        builder.addCase(getNewsList.fulfilled, (state, { payload }) => {
            state.newsList = payload.newsList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getNewsDetail.fulfilled, (state, { payload }) => {
            state.newsDetail = payload.newsDetailApiData;
        });
        builder.addCase(getEventList.fulfilled, (state, { payload }) => {
            state.eventList = payload.eventList;
            state.paginationData = payload.paginationInfo;
        });
        builder.addCase(getEventDetail.fulfilled, (state, { payload }) => {
            state.eventDetail = payload.eventDetailInfo;
        });
    },
});

export default boardSlice;