import { createSlice } from '@reduxjs/toolkit';

export const ScrollLock = () => {
  document.body.style.cssText = `
  position: fixed; 
  top: -${window.scrollY}px;
  overflow-y: scroll;
  width: 100%;`;
};

export const ScrollUnlock = () => {
  const scrollY = document.body.style.top;
  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};

const initialState = {
  isCookieAgreeLPopUp: false,
  isFixLPopUp: false,
  depth1LPopUpName: '',
  isCb1LPopUp: '',
  isCb2LPopUp: '',
  isChContLPopUp: false,
};

export const layerPopUpSlice = createSlice({
  name: 'layerPopUp',
  initialState,
  reducers: {
    actIsFixLPopUp: (state, { payload }) => {
      state.isFixLPopUp = payload;
    },
    actShowDepth1LPopUp: (state, { payload }) => {
      state.depth1LPopUpName = payload;
      ScrollLock();
    },
    actChangeDepth1LPopUp: (state) => {
      state.depth1LPopUpName = '';
    },
    actHideDepth1LPopUp: (state) => {
      state.depth1LPopUpName = '';
      ScrollUnlock();
    },
    actCb1LPopUp: (state, { payload }) => {
      state.isCb1LPopUp = payload;
    },
    actCb2LPopUp: (state, { payload }) => {
      state.isCb2LPopUp = payload;
    },
    actChContLPopUp: (state, { payload }) => {
      state.isChContLPopUp = payload;
    },
    actIsCookieAgreeLPopUp: (state, { payload }) => {
      state.isCookieAgreeLPopUp = payload;
    },
  },
});

export const {
  actIsFixLPopUp,
  actShowDepth1LPopUp,
  actChangeDepth1LPopUp,
  actHideDepth1LPopUp,
  actCb1LPopUp,
  actCb2LPopUp,
  actChContLPopUp,
  actIsCookieAgreeLPopUp,
} = layerPopUpSlice.actions;

export default layerPopUpSlice;
