import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { getNoticeDetail, getNoticeFixDetail } from '../../../../../utile/slice/boardSlice';
import PaginationBoard from '../../../../common/PaginationBoard';

function Page() {
  const navigate = useNavigate();
  const seqNum = useParams();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const noticeDetail = useAppSelector(state => state.board.noticeDetail);

  useEffect(() => {
    if(location.state == "y"){
      dispatch(getNoticeFixDetail(seqNum.bbsSeq));
    }
    else {
      dispatch(getNoticeDetail(seqNum.bbsSeq));
    }
  }, [seqNum]);

  const goDetail = (seq) => {
    navigate(`/customer/notice/${seq}`);
  }

  const goList = () => {
    navigate('/customer/notice', {state: noticeDetail.currentPageNo});
  }

  return (
    <>
      <div className="board" key={noticeDetail.bbsSeq}>
        <span className="date">{noticeDetail.createDate}</span>
        <strong className="tlt">{noticeDetail.bbsTitNm}</strong>
        <div className="cont">
          <div className="img_wrap">
            <img src={noticeDetail.fileFullPath} alt="" />
          </div>
          <p className="desc" dangerouslySetInnerHTML={{ __html: noticeDetail.bbsCont }} />
        </div>
      </div>
      <PaginationBoard detailData={noticeDetail} goDetail={goDetail} goList={goList} />
    </>
  );
}

export default Page;
