import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHeaderScroll } from '../../utile/hook/useScrollHook';
import useWindowSize from '../../utile/hook/useWindowSize';
import ReserBtn from '../common/ReserBtn';

function Header() {
  const scrollDirection = useHeaderScroll();
  const windowSize = useWindowSize();

  const headerRef = useRef(null);
  const [headerActive, setHeaderActive] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const handleHamburgerClick = () => setIsOpen((prevState) => !prevState);

  const { pathname } = window.location;

  let headerClassNames = 'navbar';
  if (headerActive) headerClassNames += ' active';

  if (pathname.includes('/model/introduce')) {
    if (scrollDirection === 'showDown' || scrollDirection === 'showUp')
      headerClassNames += ` showDown`;
  } else {
    headerClassNames += ` ${scrollDirection}`;
  }

  useEffect(() => {
    closeHeader();
    headerRef.current?.removeEventListener('mouseenter', openHeader);
    headerRef.current?.removeEventListener('mouseleave', closeHeader);
  }, [scrollDirection]);

  // useEffect(() => {}, [scrollDirection]);

  const openHeader = () => setHeaderActive(true);
  const closeHeader = () => setHeaderActive(false);

  useEffect(() => {
    if (windowSize.width > 1020) {
      headerRef.current?.addEventListener('mouseover', openHeader);
      headerRef.current?.addEventListener('mouseout', closeHeader);
      return () => {
        headerRef.current?.removeEventListener('mouseenter', openHeader);
        headerRef.current?.removeEventListener('mouseleave', closeHeader);
      };
    }
  }, [windowSize.width]);

  const handleMenuClick = (e) => {
    e.preventDefault();
    // closeHeader();
    // headerRef.current.removeEventListener('mouseenter', openHeader);
  };
  const handleSubMenuItemClick = () => {
    closeHeader();
    setIsOpen(false);
  };

  function moSubOpen(e) {
    const submenu = e.target.closest('ul').querySelectorAll('a');
    submenu.forEach((item) => {
      item.classList.remove('active');
    });
    const sub = e.target.closest('li').querySelectorAll('a');
    sub.forEach((item) => item.classList.toggle('active'));
  }

  useEffect(() => {
    closeHeader();
  }, []);

  return (
    <header className={headerClassNames} ref={headerRef}>
      <div className="top_menu">
        <Link to="/" aria-label="홈으로 이동">
          <h1 className="logo" onClick={closeHeader}></h1>
        </Link>
        <nav className="pc_view">
          <ul className="main_menu">
            <li>
              <a href="#!" role="button" onClick={handleMenuClick}>
                브랜드
              </a>
            </li>
            <li>
              <a href="#!" role="button" onClick={handleMenuClick}>
                모델
              </a>
            </li>
            <li>
              <a href="#!" role="button" aria-label="구매" onClick={handleMenuClick}>
                구매
              </a>
            </li>
            <li>
              <a href="#!" role="button" aria-label="서비스" onClick={handleMenuClick}>
                서비스
              </a>
            </li>
            <li>
              <a href="#!" role="button" aria-label="고객지원" onClick={handleMenuClick}>
                고객지원
              </a>
            </li>
          </ul>
          <ul className="main_menu mo_view">
            <li>
              <a href="#!" role="button" aria-label="브랜드">
                브랜드
              </a>
            </li>
            <li>
              <a href="#!" role="button" aria-label="모델">
                모델
              </a>
            </li>
            <li>
              <a href="#!" role="button" aria-label="구매">
                구매
              </a>
            </li>
            <li>
              <a href="#!" role="button" aria-label="서비스">
                서비스
              </a>
            </li>
            <li>
              <a href="#!" role="button" aria-label="고객지원">
                고객지원
              </a>
            </li>
          </ul>
        </nav>
        <div className="pc_view">
          <ReserBtn closeHeader={closeHeader} />
        </div>
        <button
          type="button"
          aria-label="메뉴 열기"
          className="ham_menu tablet_view"
          onClick={handleHamburgerClick}
        />
      </div>
      <ul className={headerActive ? 'sub_menu active' : 'sub_menu'} data-show={isOpen}>
        <li>
          <button type="button" className="sub_mu_tlt tablet_view" onClick={moSubOpen}>
            브랜드
          </button>
          <Link to="/brand/byd" aria-label="BYD 소개" onClick={handleSubMenuItemClick}>
            BYD 소개
          </Link>
          <Link to="/brand/gsg" aria-label="GSG 소개" onClick={handleSubMenuItemClick}>
            GSG 소개
          </Link>
        </li>
        <li>
          <button type="button" className="sub_mu_tlt tablet_view" onClick={moSubOpen}>
            모델
          </button>
          <Link to="/model/introduce" aria-label="T4K" onClick={handleSubMenuItemClick}>
            T4K
          </Link>
          <Link to="/model/gallery" aria-label="갤러리" onClick={handleSubMenuItemClick}>
            갤러리
          </Link>
          <Link to="/model/performance" aria-label="성능 및 제원" onClick={handleSubMenuItemClick}>
            성능 및 제원
          </Link>
          <Link to="/model/price" aria-label="가격" onClick={handleSubMenuItemClick}>
            가격
          </Link>
        </li>
        <li>
          <button type="button" className="sub_mu_tlt tablet_view" onClick={moSubOpen}>
            구매
          </button>
          {/* <Link to="/buy/reservation" aria-label="구매상담 신청" onClick={handleSubMenuItemClick}> */}
          <Link to="/buy/reservation" aria-label="구매상담 신청" onClick={handleSubMenuItemClick}>
            구매상담 신청
          </Link>
          <Link to="/buy/estimate/select" aria-label="견적" onClick={handleSubMenuItemClick}>
            견적
          </Link>
          <Link to="/service/network" aria-label="판매 네트워크" onClick={handleSubMenuItemClick}>
            판매 네트워크
          </Link>
          <Link to="/buy/guide" aria-label="구매가이드" onClick={handleSubMenuItemClick}>
            구매가이드
          </Link>
          <Link to="/buy/tco" aria-label="EV 절약계산기" onClick={handleSubMenuItemClick}>
            EV 절약계산기
          </Link>
          <Link to="/buy/subven" aria-label="구매보조금" onClick={handleSubMenuItemClick}>
            구매보조금
          </Link>
        </li>
        <li>
          <button type="button" className="sub_mu_tlt tablet_view" onClick={moSubOpen}>
            서비스
          </button>
          <Link to="/service/info" aria-label="서비스 안내" onClick={handleSubMenuItemClick}>
            서비스 안내
          </Link>
          <Link to="/service/network" aria-label="서비스 네트워크" onClick={handleSubMenuItemClick}>
            서비스 네트워크
          </Link>
          <Link to="/service/part" aria-label="부품정보" onClick={handleSubMenuItemClick}>
            부품정보
          </Link>
          <Link to="/service/guide" aria-label="차량관리 가이드" onClick={handleSubMenuItemClick}>
            차량관리 가이드
          </Link>
        </li>
        <li>
          <button type="button" className="sub_mu_tlt tablet_view" onClick={moSubOpen}>
            고객지원
          </button>
          <Link to="/customer/notice" aria-label="공지사항" onClick={handleSubMenuItemClick}>
            공지사항
          </Link>
          <Link to="/customer/faq" aria-label="FAQ" onClick={handleSubMenuItemClick}>
            FAQ
          </Link>
          <Link to="/customer/contact" aria-label="문의하기" onClick={handleSubMenuItemClick}>
            문의하기
          </Link>
          <Link to="/customer/news" aria-label="SNS" onClick={handleSubMenuItemClick}>
            SNS
          </Link>
          <Link to="/customer/event" aria-label="제휴이벤트" onClick={handleSubMenuItemClick}>
            제휴이벤트
          </Link>
        </li>
        <li className="tablet_view">
          <button
            type="button"
            aria-label="메뉴 닫기"
            className="ham_menu_del"
            onClick={handleHamburgerClick}
          />
        </li>
        <li className="tablet_view">
          <ReserBtn closeHeader={closeHeader} setIsOpen={setIsOpen} />
        </li>
      </ul>
    </header>
  );
}

export default Header;
