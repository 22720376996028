import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReserBtn from '../common/ReserBtn';

function Footer() {
  const [activedMenu, setActivedMenu] = useState(null);

  const moSubOpen = (event) => {
    console.log(event.target.name, activedMenu);
    if (event.target.name === activedMenu) {
      setActivedMenu(null);
      return;
    }

    setActivedMenu(event.target.name);
  };

  const { pathname } = window.location;
  if (
    pathname === '/buy/estimate/model' ||
    pathname === '/buy/estimate/color' ||
    pathname === '/buy/estimate/option' ||
    pathname === '/buy/estimate/detail' ||
    pathname === '/buy/document/docreserv'
  )
    return null;

  return (
    <footer>
      <div className="inner">
        <div className="ft_top">
          <ul>
            <li data-active={activedMenu === '1'}>
              <strong className="ft_mu_tlt">
                브랜드
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="1"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/brand/byd" title="BYD 소개">
                BYD 소개
              </Link>
              <Link to="/brand/gsg" title="GSG 소개">
                GSG 소개
              </Link>
            </li>
            <li data-active={activedMenu === '2'}>
              <strong className="ft_mu_tlt">
                모델
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="2"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/model/introduce" title="T4K">
                T4K
              </Link>
              <Link to="/model/gallery" title="갤러리">
                갤러리
              </Link>
              <Link to="/model/performance" title="성능 및 제원">
                성능 및 제원
              </Link>
              <Link to="/model/price" title="가격">
                가격
              </Link>
            </li>
            <li data-active={activedMenu === '3'}>
              <strong className="ft_mu_tlt">
                구매
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="3"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/buy/reservation" title="구매상담 신청">
                구매상담 신청
              </Link>
              <Link to="/buy/estimate/select" title="견적">
                견적
              </Link>
              <Link to="/service/network" title="판매 네트워크">
                판매 네트워크
              </Link>
              <Link to="/buy/guide" title="구매가이드">
                구매가이드
              </Link>
              <Link to="/buy/tco" title="EV 절약계산기">
                EV 절약계산기
              </Link>
              <Link to="/buy/subven" title="구매보조금">
                구매보조금
              </Link>
            </li>
            <li data-active={activedMenu === '4'}>
              <strong className="ft_mu_tlt">
                서비스
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="4"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/service/info" title="서비스 안내">
                서비스 안내
              </Link>
              <Link to="/service/network" title="서비스 네트워크">
                서비스 네트워크
              </Link>
              <Link to="/service/part" title="부품정보">
                부품정보
              </Link>
              <Link to="/service/guide" title="차량관리 가이드">
                차량관리 가이드
              </Link>
            </li>
            <li data-active={activedMenu === '5'}>
              <strong className="ft_mu_tlt">
                고객지원
                <button
                  type="button"
                  className="mo_view ft_mu_more"
                  title="메뉴 열기"
                  name="5"
                  onClick={moSubOpen}
                ></button>
              </strong>
              <Link to="/customer/notice" title="공지사항">
                공지사항
              </Link>
              <Link to="/customer/faq" title="FAQ">
                FAQ
              </Link>
              <Link to="/customer/contact" title="문의하기">
                문의하기
              </Link>
              <Link to="/customer/news" title="SNS">
                SNS
              </Link>
              <Link to="/customer/event" title="제휴이벤트">
                제휴이벤트
              </Link>
            </li>
          </ul>
          <div className="pc_view">
            <ReserBtn type="wh" />
          </div>
        </div>
        <div className="ft_btm">
          <div className="logo">
            <div className="ft_logo_gs">
              <span className="blind">gs clobal</span>
            </div>
            <div className="ft_logo_byd">
              <span className="blind">byd</span>
            </div>
          </div>
          <div className="info">
            <Link to="/personal-info" className="link_b" title="개인정보처리방침">
              개인정보처리방침
            </Link>
            {/* <Link to="/terms" className="link_s" title="사이트 이용약관">
              사이트 이용약관
            </Link> */}
            <div className="link_s">GS Global &copy; 2023 All rights reserved</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
