import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getNewsList } from '../../../../utile/slice/boardSlice';
import { actIsLoading } from '../../../../utile/slice/loadingSlice';
import PaginationList from '../../../common/pagination/BoardPagination';

import EmptyImage from '../../../../assets/images/list_empty.png';

function NewsList() {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const newsList = useAppSelector((state) => state.board.newsList);
  const Page = useAppSelector((state) => state.board.paginationData);
  const [pageNo, setPageNo] = useState(state ? state : 1);

  useEffect(() => {
    dispatch(getNewsList(pageNo));
  }, [pageNo]);

  const onChangePageNo = (pageNum) => {
    setPageNo(pageNum);
  };

  const onClickList = () => {
    dispatch(actIsLoading(true));
    setTimeout(() => {
      dispatch(actIsLoading(false));
    }, 2000);
  };

  return (
    <>
      <ul className="list_wrap2">
        {newsList.map((news) => (
          <li key={news.bbsSeq}>
            <Link
              to={'/customer/news/' + news.bbsSeq}
              title="페이지 이동"
              className="card_cont1"
              onClick={onClickList}
            >
              <div className="img_wrap">
                {news.fileFullPath && <img src={news.fileFullPath} alt="" />}
              </div>
              <strong className="tlt">{news.bbsTitNm}</strong>
              {/* <p className="desc pc_view" dangerouslySetInnerHTML={{ __html: news.bbsCont }} /> */}
              <span className="date">{news.createDate}</span>
            </Link>
          </li>
        ))}
      </ul>
      <PaginationList paginationData={Page} currentNum={pageNo} setCurrentNum={onChangePageNo} />
    </>
  );
}

export default NewsList;
