import React from 'react';
import PaginationList from '../../../common/pagination/BoardPagination';
import RelationPage from '../../../common/RelationPage';
import NewsList from './List';

function News() {
  return (
    <div className="news">
      <div className="inner">
        <div className="visual">
          <h2 className="tlt">SNS</h2>
        </div>
        <NewsList />
        {/* <PaginationList /> */}
      </div>
      <RelationPage />
    </div>
  );
}

export default News;
