import { createSlice } from '@reduxjs/toolkit';

const ScrollLock = () => {
  document.body.style.cssText = `
  position: fixed; 
  top: -${window.scrollY}px;
  overflow-y: scroll;
  width: 100%;`;
};

const ScrollUnlock = () => {
  const scrollY = document.body.style.top;
  document.body.style.cssText = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};

const initialState = {
  isShowAPopUp: false,
  isEffectToParentsAPopUp: false,
  isCb1APopUp: false,
  isCb2APopUp: false,
  message: '',
  title: '',
  cntBtn: 0,
  btnName: {},
  img: '', //컴포넌트명('string')으로 구분
};

const alartPopUpSlice = createSlice({
  name: 'alartPopUp',
  initialState,
  reducers: {
    actShowAPopUp: (state, { payload }) => {
      state.isShowAPopUp = true;
      state.message = payload.message;
      state.title = payload.title;
      state.cntBtn = payload.cntBtn;
      state.btnName = payload.btnName;
      state.img = payload.img;
      ScrollLock();
    },
    actHideAPopUp: (state) => {
      state.isShowAPopUp = false;
      state.message = '';
      state.title = '';
      state.cntBtn = 0;
      state.btnName = {};
      state.img = '';
      ScrollUnlock();
    },
    actEffectToParentsAPopUp: (state, { payload }) => {
      state.isEffectToParentsAPopUp = payload;
      ScrollUnlock();
    },
    actCb1APopUp: (state, { payload }) => {
      state.isCb1APopUp = payload;
      ScrollUnlock();
    },
    actCb2APopUp: (state, { payload }) => {
      state.isCb2APopUp = payload;
      ScrollUnlock();
    },
  },
});

export const {
  actShowAPopUp,
  actHideAPopUp,
  actEffectToParentsAPopUp,
  actCb1APopUp,
  actCb2APopUp,
} = alartPopUpSlice.actions;

export default alartPopUpSlice;
