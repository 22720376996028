import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import pin1 from '../../../../assets/images/ico/ico_pin1.png';
import pin1Hover from '../../../../assets/images/ico/ico_pin1_hover.png';
import pin2 from '../../../../assets/images/ico/ico_pin2.png';
import pin2Hover from '../../../../assets/images/ico/ico_pin2_hover.png';
import pin3 from '../../../../assets/images/ico/ico_pin3.png';
import pin3Hover from '../../../../assets/images/ico/ico_pin3_hover.png';
import { useAppDispatch } from '../../../../store';
import { getRepairTypeList } from './RepairTypeSlice';
import { getCityList, getTownList } from './mapCitySlice';
import { actIsAdd, getMarkerList } from './mapSlice';
import { getSortingList } from './sortingSlice';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// popup
import { ScrollLock, ScrollUnlock } from 'utile/slice/layerPopUpSlice';
import PopUp from './PopUp';

const a = [
  {
    cd: '11',
    addrName: '서울특별시',
    fullAddr: '서울특별시',
  },
  {
    cd: '21',
    addrName: '부산광역시',
    fullAddr: '부산광역시',
  },
  {
    cd: '22',
    addrName: '대구광역시',
    fullAddr: '대구광역시',
  },
  {
    cd: '23',
    addrName: '인천광역시',
    fullAddr: '인천광역시',
  },
  {
    cd: '24',
    addrName: '광주광역시',
    fullAddr: '광주광역시',
  },
  {
    cd: '25',
    addrName: '대전광역시',
    fullAddr: '대전광역시',
  },
  {
    cd: '26',
    addrName: '울산광역시',
    fullAddr: '울산광역시',
  },
  {
    cd: '29',
    addrName: '세종특별자치시',
    fullAddr: '세종특별자치시',
  },
  {
    cd: '31',
    addrName: '경기도',
    fullAddr: '경기도',
  },
  {
    cd: '32',
    addrName: '강원도',
    fullAddr: '강원도',
  },
  {
    cd: '33',
    addrName: '충청북도',
    fullAddr: '충청북도',
  },
  {
    cd: '34',
    addrName: '충청남도',
    fullAddr: '충청남도',
  },
  {
    cd: '35',
    addrName: '전라북도',
    fullAddr: '전라북도',
  },
  {
    cd: '36',
    addrName: '전라남도',
    fullAddr: '전라남도',
  },
  {
    cd: '37',
    addrName: '경상북도',
    fullAddr: '경상북도',
  },
  {
    cd: '38',
    addrName: '경상남도',
    fullAddr: '경상남도',
  },
  {
    cd: '39',
    addrName: '제주특별자치도',
    fullAddr: '제주특별자치도',
  },
];

const markerListMock = [
  {
    svcNwSeq: '20',
    svcNwNm: '경상권',
    svcNwDescCont: '경상권 판매센터 설명',
    svcNwTypeCd: 'C1201',
    repairTypeCd: '',
    sidoCd: '25',
    sigunguCd: '25030',
    basicAddr: '대전 서구 둔산로',
    dtlAddr: '100',
    zipNum: '35242',
    mapXVal: '127.385',
    mapYVal: '36.35',
    repContactNum: '473-7920',
    phoneNum: '1519-0014',
    operTimeDirecInpYn: 'N',
    operStartTime: '0900',
    operEndTime: '1800',
    holiCd: 'C1403',
    operTimeDirecInpCont: '',
    wayComCont: '오시는길',
    svcNwImg: null,
  },
  {
    svcNwSeq: '66',
    svcNwNm: '수도/강원권',
    svcNwDescCont: '수도/강원권 판매센터 설명',
    svcNwTypeCd: 'C1201',
    repairTypeCd: '',
    sidoCd: '11',
    sigunguCd: '11240',
    basicAddr: '서울 송파구 동남로 99',
    dtlAddr: '1001',
    zipNum: '05831',
    mapXVal: '127.122496940127',
    mapYVal: '37.4897427415779',
    repContactNum: '2270230',
    phoneNum: '010-1231-2412',
    operTimeDirecInpYn: 'N',
    operStartTime: '0000',
    operEndTime: '2400',
    holiCd: 'C1407',
    operTimeDirecInpCont: '',
    wayComCont: '오시는길',
    svcNwImg: null,
  },
  {
    svcNwSeq: '19',
    svcNwNm: '충청/전라권',
    svcNwDescCont: '충청/전라권 판매센터 설명',
    svcNwTypeCd: 'C1201',
    repairTypeCd: '',
    sidoCd: '21',
    sigunguCd: '21130',
    basicAddr: '부산 연제구 중앙대로',
    dtlAddr: '1001',
    zipNum: '47545',
    mapXVal: '129.075',
    mapYVal: '35.18',
    repContactNum: '227-0230',
    phoneNum: '7956-4111',
    operTimeDirecInpYn: 'Y',
    operStartTime: '',
    operEndTime: '',
    holiCd: 'C1402',
    operTimeDirecInpCont: '운영시간 직접 입력 테스트',
    wayComCont: '오시는길',
    svcNwImg: null,
  },
  {
    svcNwSeq: '31',
    svcNwNm: 'autoOasis 가좌IC점',
    svcNwDescCont: '-',
    svcNwTypeCd: 'C1202',
    repairTypeCd: 'C1303',
    sidoCd: '11',
    sigunguCd: '11160',
    basicAddr: '인천 서구 백범로 674',
    dtlAddr: '',
    zipNum: '22825',
    mapXVal: '126.682213942162',
    mapYVal: '37.4806252296706',
    repContactNum: '0507-1313-0294',
    phoneNum: '-',
    operTimeDirecInpYn: 'Y',
    operStartTime: '',
    operEndTime: '',
    holiCd: 'C1408',
    operTimeDirecInpCont: '-',
    wayComCont: '-',
    svcNwImg: null,
  },
  {
    svcNwSeq: '34',
    svcNwNm: 'autoOasis 김포 한강신도시점',
    svcNwDescCont: '-',
    svcNwTypeCd: 'C1202',
    repairTypeCd: 'C1303',
    sidoCd: '31',
    sigunguCd: '31230',
    basicAddr: '경기 김포시 김포한강3로 400',
    dtlAddr: '',
    zipNum: '10066',
    mapXVal: '126.647512514756',
    mapYVal: '37.6417669491838',
    repContactNum: '0507-1413-7053',
    phoneNum: '-',
    operTimeDirecInpYn: 'Y',
    operStartTime: '',
    operEndTime: '',
    holiCd: 'C1408',
    operTimeDirecInpCont: '-',
    wayComCont: '-',
    svcNwImg: null,
  },
  {
    svcNwSeq: '47',
    svcNwNm: 'autoOasis 대구 대림공단점',
    svcNwDescCont:
      'autoOasis 대구 대림공단점은 EV특화점포로 전기차 관리에 필수적인 요소들을 점검해 드립니다!',
    svcNwTypeCd: 'C1202',
    repairTypeCd: 'C1303',
    sidoCd: '22',
    sigunguCd: '22070',
    basicAddr: '대구 달서구 달서대로 403',
    dtlAddr: 'GS칼텍스 주유소 내',
    zipNum: '42714',
    mapXVal: '128.491123822385',
    mapYVal: '35.8352205856292',
    repContactNum: '053-592-5051',
    phoneNum: '010-1234-1234',
    operTimeDirecInpYn: 'Y',
    operStartTime: '',
    operEndTime: '',
    holiCd: 'C1408',
    operTimeDirecInpCont: '08:30 ~ 18:00(평일/토요일근무)',
    wayComCont:
      '오산IC: 1번국도 평택방면 (10분 소요) 송탄출장소 직전 우측 위치\r\n\r\n\r\n안성IC: 평택방면 좌회전 → 평택외곽산업도로(평택공단) → 1번국도 진입 → 송탄공단 → 송탄출장소 지나 좌측 위치 (20분 소요) 1번국도 송탄 출장소 건너 신한은행 옆 위치',
    svcNwImg: null,
  },
  {
    svcNwSeq: '28',
    svcNwNm: 'autoOasis 덕이동점',
    svcNwDescCont: '-',
    svcNwTypeCd: 'C1202',
    repairTypeCd: 'C1303',
    sidoCd: '31',
    sigunguCd: '31104',
    basicAddr: '경기 고양시 일산서구 덕이로 174',
    dtlAddr: '',
    zipNum: '10228',
    mapXVal: '126.74109080983',
    mapYVal: '37.6940581150277',
    repContactNum: '010-9793-3400',
    phoneNum: '-',
    operTimeDirecInpYn: 'Y',
    operStartTime: '',
    operEndTime: '',
    holiCd: 'C1408',
    operTimeDirecInpCont: '-',
    wayComCont: '-',
    svcNwImg: null,
  },
  {
    svcNwSeq: '38',
    svcNwNm: 'autoOasis 롯데마트 상무점',
    svcNwDescCont: '-',
    svcNwTypeCd: 'C1202',
    repairTypeCd: 'C1303',
    sidoCd: '11',
    sigunguCd: '11160',
    basicAddr: '광주 서구 시청로 40',
    dtlAddr: '',
    zipNum: '61948',
    mapXVal: '126.852422214795',
    mapYVal: '35.1523683644087',
    repContactNum: '062-383-5112',
    phoneNum: '-',
    operTimeDirecInpYn: 'Y',
    operStartTime: '',
    operEndTime: '',
    holiCd: 'C1408',
    operTimeDirecInpCont: '-',
    wayComCont: '-',
    svcNwImg: null,
  },
  {
    svcNwSeq: '48',
    svcNwNm: 'autoOasis 롯데마트 울산점',
    svcNwDescCont: '-',
    svcNwTypeCd: 'C1202',
    repairTypeCd: 'C1303',
    sidoCd: '11',
    sigunguCd: '11230',
    basicAddr: '울산 남구 삼산로 74',
    dtlAddr: '롯데마트 울산점 2F 주차장내',
    zipNum: '44722',
    mapXVal: '129.315908483993',
    mapYVal: '35.5339781077673',
    repContactNum: '052-269-5189',
    phoneNum: '-',
    operTimeDirecInpYn: 'Y',
    operStartTime: '',
    operEndTime: '',
    holiCd: 'C1408',
    operTimeDirecInpCont: '-',
    wayComCont: '-',
    svcNwImg: null,
  },
  {
    svcNwSeq: '26',
    svcNwNm: 'autoOasis 롯데마트 의왕점',
    svcNwDescCont: '-',
    svcNwTypeCd: 'C1202',
    repairTypeCd: 'C1303',
    sidoCd: '31',
    sigunguCd: '31170',
    basicAddr: '경기 의왕시 계원대학로 7',
    dtlAddr: '',
    zipNum: '16036',
    mapXVal: '126.972871115511',
    mapYVal: '37.3805432278472',
    repContactNum: '031-425-8476',
    phoneNum: '-',
    operTimeDirecInpYn: 'Y',
    operStartTime: '',
    operEndTime: '',
    holiCd: 'C1408',
    operTimeDirecInpCont: '-',
    wayComCont: '-',
    svcNwImg: null,
  },
];

const { kakao } = window;

let map;

const mapDefault = {
  center: { lat: 36.01631971197015, lng: 126.73077881974497 },
  level: 12,
  isPanto: false,
};

function Map() {
  useEffect(() => {
    // css sticky로 교체
    // const relationHeight = document.querySelector('.relation_wrap').offsetTop;
    // const floatBtn = document.querySelector('.list_open');

    // window.addEventListener('scroll', () => {
    //   if (relationHeight - 500 < window.scrollY) {
    //     floatBtn.classList.add('move');
    //   } else {
    //     floatBtn.classList.remove('move');
    //   }
    // });

    const list = document.querySelectorAll('.list');

    list.forEach((li) => {
      if (li.clientHeight > 180) {
        li.classList.add('pr0');
      }
    });
  }, []);

  const [state, setState] = useState({
    popupOpen: false,
    list: true,
    sepNum: '',
  });

  const listCtrl = (e) => {
    setState({ ...state, list: !state.list });
  };

  const sortingList = useSelector((state) => state.sorting.sortinglist);
  const repairTypeList = useSelector((state) => state.repairType.repairTypeList);
  const cityList = useSelector((state) => state.mapCity.cityList);
  const townList = useSelector((state) => state.mapCity.townList);
  const serviceNetwork = useSelector((state) => state.serviceNetwork);
  const markerList = serviceNetwork.markerlist;
  const totalPageCount = serviceNetwork.totalPageCount;

  const dispatch = useAppDispatch();

  const [params, setParams] = useState({
    currentPage: 1,
    limit: 500,
    selectSorting: '',
    search: false,
    selectRepairType: '',
    searchSido: '',
    searchSigungu: '',
    searchText: '',
  });

  const [inputText, setInputText] = useState('');

  const [active, setActive] = useState({
    tabOne: true,
    tabTwo: false,
    tabThree: false,
    tabFour: false,
  });

  useEffect(() => {
    dispatch(getSortingList('C1200'));
    dispatch(getRepairTypeList('C1300'));
    dispatch(getCityList());
  }, []);

  useEffect(() => {
    dispatch(getMarkerList(params));
  }, [params]);

  let tabOne = [];
  let tabTwo = [];
  let tabThree = [];

  tabOne = markerList.filter((i) => i.svcNwTypeCd == 'C1201');
  tabTwo = markerList.filter((i) => i.svcNwTypeCd == 'C1202');
  tabThree = markerList.filter((i) => i.svcNwTypeCd == 'C1203');

  let imageSize = new kakao.maps.Size(40, 40);

  let arr = [];
  let arrOne = {};
  let arrTwo = {};
  let arrThree = {};

  arrOne.target = tabOne;
  arrOne.pin = pin1;
  arrOne.pinHover = pin1Hover;
  arrTwo.target = tabTwo;
  arrTwo.pin = pin2;
  arrTwo.pinHover = pin2Hover;
  arrThree.target = tabThree;
  arrThree.pin = pin3;
  arrThree.pinHover = pin3Hover;

  arr.push(arrOne, arrTwo, arrThree);

  const [search, setSearch] = useState(false);
  const [onClick, setOnClick] = useState(false);
  const [mapOption, setMapOption] = useState(mapDefault);

  const [markers, setMarkers] = useState([]);

  const [position, setPosition] = useState({
    mapYVal: mapOption.center.lat,
    mapXVal: mapOption.center.lng,
  });

  const [width, setWidth] = useState(window.innerWidth);

  /* kakao map */
  useEffect(() => {
    const container = document.querySelector('.map_item');

    if (width > 768) {
      const options = {
        center: new kakao.maps.LatLng(mapOption.center.lat, mapOption.center.lng),
        level: mapOption.level,
      };
      map = new kakao.maps.Map(container, options);

      map.relayout();
    } else {
      const options = {
        center: new kakao.maps.LatLng(38.20277647179425, 127.64576637742228),
        level: 13,
      };
      map = new kakao.maps.Map(container, options);

      container.style.height = '540px';

      map.relayout();
    }
  }, []);

  useEffect(() => {
    markers.map((e) => {
      e.setMap(null);
    });
    setMarkers([]);

    arr.map((e) => {
      e.target.map((item) => {
        let markNormal = new kakao.maps.MarkerImage(e.pinHover, imageSize);
        let markHover = new kakao.maps.MarkerImage(e.pin, imageSize);

        let marker = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(item.mapYVal, item.mapXVal),
          // title: item.svcNwNm,
          image: markNormal,
        });
        marker.setMap(map);

        setMarkers((e) => [...e, marker]);

        let content =
          '<div className="hover_tit" style="display: inline-block; padding: 16px 24px; max-width: 255px; background: rgba(0,0,0, 0.8); box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16); border-radius: 8px; color: #fff; font-weight: 600; font-size: 16px; line-height: 150%; position: absolute; bottom: -60px; left: 50%">' +
          item.svcNwNm +
          '</div>';

        let customOverlay = new kakao.maps.CustomOverlay({
          map: map,
          position: marker.getPosition(),
          content: content,
        });

        customOverlay.setMap(null);

        kakao.maps.event.addListener(marker, 'mouseover', () => {
          marker.setImage(markHover);
          customOverlay.setMap(map);
        });

        kakao.maps.event.addListener(marker, 'mouseout', () => {
          marker.setImage(markNormal);
          customOverlay.setMap(null);
        });

        kakao.maps.event.addListener(marker, 'click', () => {
          setState({
            ...state,
            popupOpen: true,
            sepNum: item.svcNwSeq,
          });
        });
      });
    });

    let moveLatLon = new kakao.maps.LatLng(mapDefault.center.lat, mapDefault.center.lng);

    if (search) {
      let foucusArray = markerList[0];
      if (markerList.length > 0) {
        if (
          params.selectRepairType === '' &&
          params.searchSido === '' &&
          params.searchSigungu === '' &&
          params.searchText === ''
        ) {
          map.setCenter(moveLatLon);
          map.setLevel(12);
        } else {
          setMapOption({
            center: { lat: foucusArray.mapYVal, lng: foucusArray.mapXVal },
            level: 5,
            isPanto: true,
          });
        }
      }
    }

    if (onClick) {
      setOnClick(false);
      setSearch(false);
      map.setCenter(new kakao.maps.LatLng(position.mapYVal, position.mapXVal));
      map.setLevel(5);
    }
  }, [markerList, position]);

  useEffect(() => {
    if (search) {
      if (markerList.length > 0) {
        let moveLatLon = new kakao.maps.LatLng(mapOption.center.lat, mapOption.center.lng);
        let level = 5;
        map.setLevel(level);
        map.panTo(moveLatLon);
      }
    }
  }, [mapOption]);

  const onClickSorting = (e) => {
    let value = e.target.value;

    setSearch(false);
    dispatch(actIsAdd(false));
    setInputText('');

    let moveLatLon = new kakao.maps.LatLng(mapDefault.center.lat, mapDefault.center.lng);
    map.setCenter(moveLatLon);
    map.setLevel(12);

    setParams({
      ...params,
      currentPage: 1,
      selectSorting: value,
      search: false,
      selectRepairType: '',
      searchSido: '',
      searchSigungu: '',
      searchText: '',
    });

    if (value == '') {
      setActive({
        tabOne: true,
        tabTwo: false,
        tabThree: false,
        tabFour: false,
      });
    } else if (value == 'C1201') {
      setActive({
        tabOne: false,
        tabTwo: true,
        tabThree: false,
        tabFour: false,
      });
    } else if (value == 'C1202') {
      setActive({
        tabOne: false,
        tabTwo: false,
        tabThree: true,
        tabFour: false,
      });
    } else if (value == 'C1203') {
      setActive({
        tabOne: false,
        tabTwo: false,
        tabThree: false,
        tabFour: true,
      });
    }
  };
  /*  // kakao map */
  // const onClickSorting = () => console.log();

  const onChangeRepairType = (e) => {
    setParams({ ...params, search: true, selectRepairType: e.target.value });
    setSearch(true);
  };

  const onChangeSelectSido = (e) => {
    let value = e.target.value;

    setSearch(true);

    if (!value) {
      setParams({ ...params, searchSido: value, searchSigungu: value });
      dispatch(getCityList());
      dispatch(getTownList());
    } else {
      setParams({ ...params, search: true, searchSido: value });
      dispatch(getTownList(value));
    }
  };

  const onChangeSelectSigungu = (e) => {
    setParams({ ...params, search: true, searchSigungu: e.target.value });
    setSearch(true);
  };

  const onChangeText = (e) => {
    setInputText(e.target.value);
  };

  const onClickSearch = () => {
    setParams({ ...params, search: true, searchText: inputText });
    setSearch(true);
  };

  // const onClickMore = () => {
  //   if (totalPageCount.currentPageNo < totalPageCount.totalPageCount) {
  //     dispatch(actIsAdd(true));
  //     setParams({ ...params, currentPage: params.currentPage + 1 });
  //   }
  // };

  const hideModal = () => {
    setState({
      ...state,
      popupOpen: false,
    });
  };

  // 스크롤락 테스트 못 해봄.
  useEffect(() => {
    if (state.popupOpen) {
      ScrollLock();
    } else {
      ScrollUnlock();
    }
  }, [state.popupOpen]);

  return (
    <>
      <div className="visual">
        <h2 className="tlt">판매/서비스 네트워크</h2>
      </div>
      <div className="tab-head">
        <div className="tab-inner">
          <button
            value=""
            type="button"
            className={active.tabOne ? 'sorting_btn active' : 'sorting_btn'}
            onClick={onClickSorting}
          >
            전체 네트워크
          </button>
          <button
            value="C1201"
            type="button"
            className={active.tabTwo ? 'sorting_btn active' : 'sorting_btn'}
            onClick={onClickSorting}
          >
            판매센터
          </button>
          <button
            value="C1202"
            type="button"
            className={active.tabThree ? 'sorting_btn active' : 'sorting_btn'}
            onClick={onClickSorting}
          >
            정비센터
          </button>
          <button
            value="C1203"
            type="button"
            className={active.tabFour ? 'sorting_btn active' : 'sorting_btn'}
            onClick={onClickSorting}
          >
            부품센터
          </button>
        </div>
      </div>
      <div className="map_wrap">
        <div className="map_inner">
          <div className="search_bar">
            <div className={active.tabOne ? 'top active' : 'top'}>
              <div className="form_wrap">
                <div className="input_wrap">
                  <div className="select_default readonly">
                    <select
                      id="input1"
                      className="input_select"
                      onChange={onChangeSelectSido}
                      value={params.searchSido}
                      disabled
                    >
                      <option value="">지역 전체</option>
                      {cityList.map((city) => (
                        <option value={city.cd} key={city.cd}>
                          {city.addrName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_wrap">
                <div className="input_wrap">
                  <input
                    id="input3"
                    name="input3"
                    type="text"
                    placeholder="센터/지점명 또는 도로명 주소 입력"
                    className="input_txt"
                    onChange={onChangeText}
                    value={inputText}
                  ></input>
                </div>
                <button type="button" className="btn_primary_m" onClick={onClickSearch}>
                  <span>검색</span>
                </button>
              </div>
            </div>
            {/* START : 판매센터 검색창 */}
            <div className={active.tabTwo ? 'top active' : 'top'}>
              <div className="form_wrap">
                <div className="input_wrap">
                  <input
                    id="input3"
                    name="input3"
                    type="text"
                    placeholder="판매센터명 또는 도로명 주소 입력"
                    className="input_txt"
                    onChange={onChangeText}
                    value={inputText}
                  ></input>
                </div>
                <button type="button" className="btn_primary_m" onClick={onClickSearch}>
                  <span>검색</span>
                </button>
              </div>
            </div>
            {/* END : 판매센터 검색창 */}

            {/* START : 정비센터 검색창 */}
            <div className={active.tabThree ? 'top active' : 'top'}>
              <div className="form_wrap">
                <div className="input_wrap">
                  <div className="select_layout3">
                    <div className="select_default">
                      <select
                        id="input1"
                        className="input_select"
                        onChange={onChangeRepairType}
                        value={params.selectRepairType}
                      >
                        <option value="">전체</option>
                        {repairTypeList.map((repairType) => (
                          <option value={repairType.cd} key={repairType.cd}>
                            {repairType.cdNm}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="select_default">
                      <select
                        id="input1"
                        className="input_select"
                        onChange={onChangeSelectSido}
                        value={params.searchSido}
                      >
                        <option value="">광역시/도</option>
                        {cityList.map((city) => (
                          <option value={city.cd} key={city.cd}>
                            {city.addrName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="select_default">
                      <select
                        id="input2"
                        className="input_select"
                        onChange={onChangeSelectSigungu}
                        value={params.searchSigungu}
                      >
                        <option value="">시/군/구</option>
                        {townList.map((town) => (
                          <option value={town.cd} key={town.cd}>
                            {town.addrName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_wrap">
                <div className="input_wrap">
                  <input
                    id="input3"
                    name="input3"
                    type="text"
                    placeholder="정비센터명 또는 도로명 주소 입력"
                    className="input_txt"
                    onChange={onChangeText}
                    value={inputText}
                  ></input>
                </div>
                <button type="button" className="btn_primary_m" onClick={onClickSearch}>
                  <span>검색</span>
                </button>
              </div>
            </div>
            {/* END : 정비센터 검색창 */}

            {/* START : 정비센터 검색창 */}
            <div className={active.tabFour ? 'top active' : 'top'}>
              <div className="form_wrap">
                <div className="input_wrap">
                  <div className="select_layout2">
                    <div className="select_default">
                      <select
                        id="input1"
                        className="input_select"
                        onChange={onChangeSelectSido}
                        value={params.searchSido}
                      >
                        <option value="">광역시/도</option>
                        {cityList.map((city) => (
                          <option value={city.cd} key={city.cd}>
                            {city.addrName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="select_default">
                      <select
                        id="input2"
                        className="input_select"
                        onChange={onChangeSelectSigungu}
                        value={params.searchSigungu}
                      >
                        <option value="">시/군/구</option>
                        {townList.map((town) => (
                          <option value={town.cd} key={town.cd}>
                            {town.addrName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_wrap">
                <div className="input_wrap">
                  <input
                    id="input3"
                    name="input3"
                    type="text"
                    placeholder="부품센터명 또는 도로명 주소 입력"
                    className="input_txt"
                    onChange={onChangeText}
                    value={inputText}
                  ></input>
                </div>
                <button type="button" className="btn_primary_m" onClick={onClickSearch}>
                  <span>검색</span>
                </button>
              </div>
            </div>
            {/* END : 정비센터 검색창 */}

            <div className={state.list ? 'btm active' : 'btm hide'}>
              <ul className="search_list">
                {markerList.length == 0 && <p>검색된 결과가 없습니다. 다시 검색해주세요.</p>}
                {markerList.map((marker) => {
                  const handleCenterInfoClick = () => {
                    setState({
                      ...state,
                      popupOpen: !state.popupOpen,
                      sepNum: marker.svcNwSeq,
                    });
                  };
                  const handlePositionClick = () => {
                    setPosition({
                      ...position,
                      mapYVal: Number(marker.mapYVal),
                      mapXVal: Number(marker.mapXVal),
                    });
                    setOnClick(true);
                  };
                  return (
                    <li className="list" key={marker.svcNwSeq} onClick={handlePositionClick}>
                      <div className="tlt_wrap">
                        <strong className="tlt">{marker.svcNwNm}</strong>
                        <div className="com">
                          {marker.repairTypeCd != '' && (
                            <span
                              className={
                                (marker.repairTypeCd == 'C1301' && 'btn_com_color1') ||
                                (marker.repairTypeCd == 'C1302' && 'btn_com_color2') ||
                                (marker.repairTypeCd == 'C1303' && 'btn_com_color3')
                              }
                            >
                              {(marker.repairTypeCd == 'C1301' && '종합1급') ||
                                (marker.repairTypeCd == 'C1302' && '소형2급') ||
                                (marker.repairTypeCd == 'C1303' && '전문3급')}
                            </span>
                          )}
                          <button className="go_to" title="페이지 이동">
                            <i className="ico_20_next_bk"></i>
                          </button>
                        </div>
                      </div>
                      <p className="adress">주소 : {marker.basicAddr + marker.dtlAddr}</p>
                      <span className="tel">전화 : {marker.repContactNum}</span>
                      <div className="button-group">
                        <button className="btn_tertiary_s" onClick={handleCenterInfoClick}>
                          센터 안내
                        </button>
                        {marker.svcNwTypeCd == 'C1201' && (
                          <Link to="/buy/reservation" className="btn_second_s">
                            <span>구매상담 신청</span>
                          </Link>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
              {/* {totalPageCount.currentPageNo < totalPageCount.totalPageCount && (
                <button type="button" className="btn_txt_bk" onClick={onClickMore}>
                  <i className="ico_20_more"></i>
                  <span>더보기</span>
                </button>
              )} */}
            </div>
          </div>
          <div className={state.list ? 'map_item hide' : 'map_item active'}>
            {/* START : map을 여기에 넣어주세요. */}
            {/* END : map을 여기에 넣어주세요 */}
          </div>
          {markerList.length > 0 && (
            <button
              type="button"
              title="리스트 열기"
              className="list_open btn_radius mo_view"
              onClick={listCtrl}
            >
              <i className={state.list ? 'ico_20_pin' : 'ico_20_go_list'}></i>
              <span>{state.list ? '지도 보기' : '목록 보기'}</span>
            </button>
          )}
        </div>

        {/* 23.04.04 지도 핀 호버시 올라오는 타이틀 효과 추가 (반영시 삭제) */}
        {/* <div className="hover_tit">autoOasis 이마트주유소 원주점</div> */}

        <ul className="info pc_view">
          {!active.tabThree && (
            <li className="sub">
              ※ 서비스 네트워크는 운영상의 사유로 수시로 변동될 수 있습니다. 방문 전 유선 문의
              바랍니다.
            </li>
          )}
          {active.tabThree && (
            <li className="sub">
              ※ 종합 서비스 네트워크 : T4K의 보증수리와 일반 점검/정비 및 판금/도장 등의 사고수리가
              가능한 네트워크 <br />
              ※ 전문 서비스 네트워크 : T4K의 보증수리와 일반 점검/정비가 가능한 네트워크 <br />※
              서비스 네트워크는 운영상의 사유로 수시로 변동될 수 있습니다. 방문 전 유선 문의
              바랍니다.
            </li>
          )}
        </ul>

        {/* popup */}
        {state.popupOpen && <PopUp sepNum={state.sepNum} hideModal={hideModal} />}
      </div>
    </>
  );
}

export default React.memo(Map);
