import React from 'react';
import PaginationList from '../../../common/pagination/BoardPagination';
import RelationPage from '../../../common/RelationPage';
import NoticeList from './List';

function Notice() {
  return (
    <div className="notice">
      <div className="inner">
        <div className="visual">
          <h2 className="tlt">공지사항</h2>
        </div>
        <NoticeList />
        {/* <PaginationList /> */}
      </div>
      <RelationPage />
    </div>
  );
}

export default Notice;
