export const desktopLnb = [
  { range: [2, 3, 4], name: 'Battery' },
  { range: [5], name: 'V2L' },
  { range: [6], name: 'Convenience' },
  { range: [7, 8], name: 'Smartpad' },
  { range: [9], name: 'Driving' },
  { range: [10], name: 'Specific' },
  { range: [11], name: '360VR' },
  { range: [12], name: 'Design' },
  { range: [13], name: 'Space' },
  { range: [14], name: 'Model' },
];
// export const mobileLnb = [
//   { id: 2, range: [2, 3, 4], name: 'Battery', active: false, position: [0, 88] },
//   { id: 5, range: [5], name: 'V2L', active: false, position: [90, 50] },
//   { id: 6, range: [6], name: 'Convenience', active: false, position: [157, 109] },
//   { id: 7, range: [7, 8], name: 'Smartpad', active: false, position: [274, 99] },

//   { id: 9, range: [9], name: 'Driving', active: false, position: [388, 64] },

//   { id: 10, range: [10], name: 'Specific', active: false, position: [470, 74] },
//   { id: 11, range: [11], name: '360VR', active: false, position: [560, 68] },
//   { id: 12, range: [12], name: 'Design', active: false, position: [647, 60] },
//   { id: 13, range: [13], name: 'Space', active: false, position: [726, 60] },
//   { id: 14, range: [14], name: 'Model', active: false, position: [803, 60] },
// ];

export const spaceDrivers = [
  // {
  //   id: 1,
  //   name: '운전석 전동시트',
  //   desc: '운전석 전동시트를 조정하여 편안한 운전환경을 제공합니다.',
  //   pos: [-100, -100],
  //   mobilePos: [-100, -100],
  // },
  {
    id: 1,
    name: '좌석 리클라이닝&슬라이딩 시트',
    desc: '좌석 리클라이닝 조절을 통해 편안한 운전 환경 제공',
    pos: [53, 47],
    mobilePos: [34, 46],
    tabletPos: [38, 38],
    // imgSrc: './../../../../assets/images/introduce/pc/img_seat1.jpg',
  },
  { id: 2, name: '시트 헤드레스트', pos: [80, 8], mobilePos: [80, 30], tabletPos: [76, 18] },
  {
    id: 3,
    name: '운전석 럼버서포트',
    desc: '전동식 럼버서포트를 통해 최적의 운전 환경 제공',
    pos: [84, 91],
    mobilePos: [80, 70],
    tabletPos: [76, 70],
  },
  {
    id: 4,
    name: '인조가죽시트',
    desc: '',
    pos: [70, 71],
    mobilePos: [60, 60],
    tabletPos: [60, 55],
  },
  // {
  //   id: 6,
  //   name: '운전석 통풍/열선 시트',
  //   desc: '열선 시트와 통풍 시트 기본 장착, 열선과 통풍 스위치를 누르면 작동.',
  //   pos: [-100, -100],
  //   mobilePos: [-100, -100],
  // },
  { id: 5, name: '후방 수납박스', pos: [94, 64], mobilePos: [97, 62], tabletPos: [90, 60] },
  {
    id: 6,
    name: '센터 시트백 콘솔박스',
    desc: '시트백 콘솔박스에 물품 보관 가능 공간 제공',
    pos: [53, 61],
    mobilePos: [30, 52],
    tabletPos: [34, 46],
  },
  { id: 7, name: '컵홀더', pos: [46, 63], mobilePos: [20, 54], tabletPos: [26, 48] },
  {
    id: 8,
    name: '핸드폰 무선 충전기(15W)',
    desc: '15W 휴대폰 급속충전 편의기능 제공',
    pos: [56, 79],
    mobilePos: [30, 59],
    tabletPos: [40, 56],
  },
];
