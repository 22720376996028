import React, { useState, useEffect } from 'react';
import RelationPage from '../../../common/RelationPage';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay]);

function Gallery() {
  useEffect(() => {
    const video = document.querySelectorAll('video');

    for (let i = 0; i < video.length; i++) {
      video[i].addEventListener('timeupdate', function () {
        setState({
          ...state,
          playedSeconds: video[i].currentTime,
          loadedSeconds: video[i].duration,
        });
      });
    }
  }, []);

  const video = document.querySelectorAll('.gall-player');
  function togglePlay(e) {
    e.target.classList.toggle('stop');
    for (let i = 0; i < video.length; i++) {
      const method = video[i].paused ? 'play' : 'pause';
      video[i][method]();
    }
  }

  const gallList = [
    {
      key: 0,
      type: 'video',
      title: '어제까지의 트럭에서 내려오자. BYD T4K로 올라오자',
      src: 'https://www.youtube.com/embed/v6NB4iJ2DHY?controls=0',
    },
    {
      key: 1,
      type: 'video',
      title: 'More than OK, 새롭게 만나는 전기트럭 T4K',
      src: 'https://www.youtube.com/embed/2T62iLM52Pc?controls=0',
    },
    // {
    //   key: 2,
    //   type: 'video',
    //   title: '어제까지의 트럭에서 내려오자. BYD T4K로 올라오자',
    //   src: 'https://www.youtube.com/embed/v6NB4iJ2DHY?controls=0',
    // },
    // {
    //   key: 3,
    //   type: 'video',
    //   title: 'More than OK, 새롭게 만나는 전기트럭 T4K',
    //   src: 'https://www.youtube.com/embed/2T62iLM52Pc?controls=0',
    // },
  ];
  const [state, setState] = useState({
    playedSeconds: 0, // 재생 진행률
    loadedSeconds: 0, // 전체 재생
  });
  const [media, setMedia] = useState({
    type: 'video', // main type
    imgSrc: '', // type img경우 src
    videoSrc: 'https://www.youtube.com/embed/v6NB4iJ2DHY?controls=0', // type video경우 src
    title: '어제까지의 트럭에서 내려오자. BYD T4K로 올라오자', // main title
  });

  function gallChange(e) {
    // const ctrl = document.querySelector('.ctrl');
    const index = e.target.closest('button').className.split('_')[1];

    // if (ctrl.classList.contains('stop')) {
    //   ctrl.classList.remove('stop');
    // }
    // type video
    if (gallList[index].type === 'video') {
      setMedia({
        ...media,
        type: 'video',
        title: gallList[index].title,
        videoSrc: gallList[index].src,
      });
    } else {
      // type img
      setMedia({
        ...media,
        type: 'img',
        title: gallList[index].title,
        imgSrc: gallList[index].src,
      });
    }
  }

  return (
    <div className="gallery">
      <div className="inner">
        <div className="visual">
          <h2 className="tlt">갤러리</h2>
        </div>
        <div className="gallery_wrap pc_view">
          {media.type === 'video' ? (
            <div className="gall_main">
              <div className="player-wrapper">
                {/* <video
                  id="video"
                  className="gall-player"
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                  loop={true}
                  controls={false}
                  style={{ width: '100%', height: '750px', objectFit: 'cover' }}
                  src={media.videoSrc}
                >
                  <source src={media.videoSrc} type="video/mp4"></source>
                </video> */}
                <iframe
                  width="100%"
                  height="100%"
                  src={media.videoSrc}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              {/* <div className="circle_progress_wrap">
                <button
                  type="button"
                  title="비디오 컨트롤"
                  onClick={togglePlay}
                  className="ctrl"
                ></button>
              </div> */}
              <strong className="tlt">{media.title}</strong>
            </div>
          ) : (
            <div className="gall_main">
              <img src={media.imgSrc} alt="" />
              <strong className="tlt">{media.title}</strong>
            </div>
          )}
          <div className="gall_list">
            <Swiper
              slidesPerView="auto"
              scrollbar={{ draggable: true }}
              pagination={{ clickable: true }}
            >
              {gallList.map((item, i) => (
                <SwiperSlide>
                  <button key={i} type="button" className={'item_' + item.key} onClick={gallChange}>
                    <em className="tlt">{item.title}</em>
                  </button>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="gallery_wrap mo_view">
          <Swiper
            slidesPerView={1}
            scrollbar={{ draggable: true }}
            navigation
            pagination={{ type: 'fraction' }}
          >
            {gallList.map((item, index) =>
              item.type === 'video' ? (
                <SwiperSlide key={index}>
                  <div className="gall_main">
                    <div className="player-wrapper">
                      <iframe
                        width="100%"
                        height="210px"
                        src={item.src}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <strong className="tlt">{item.title}</strong>
                    {/* <div className="circle_progress_wrap">
                      <button
                        type="button"
                        title="비디오 컨트롤"
                        onClick={togglePlay}
                        className="ctrl"
                      ></button>
                    </div> */}
                  </div>
                </SwiperSlide>
              ) : (
                <SwiperSlide>
                  <div className="gall_main">
                    <img src={item.src} alt="" />
                    <strong className="tlt">{item.title}</strong>
                  </div>
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </div>
      </div>
      <RelationPage />
    </div>
  );
}

export default Gallery;
